import React from 'react'
import { Link } from 'react-router-dom'
import rgslogo from '../assets/rgslogo.svg'

export default function Nav() {

    

    return (
        <nav className="navbar is-fixed-top" role="navigation" aria-label="main navigation">
        <div className="container">
          <div className="navbar-brand">
            <Link to="/" className="navbar-item"> 
              <img src={rgslogo} alt="logo" height="28" />
            </Link>

            <button type="button" className="navbar-burger burger" aria-label="menu" aria-expanded="false" data-target="navbarBasicExample">
              <span aria-hidden="true" />
              <span aria-hidden="true" />
              <span aria-hidden="true" />
            </button>
          </div>

          <div id="navbarBasicExample" className="navbar-menu">
            <div className="navbar-start">
              <Link to="/news" className="navbar-item">News</Link>

              <Link to="/games" className="navbar-item">Games</Link>

              <Link to="/team" className="navbar-item">Studios</Link>

              <Link to="/website" className="navbar-item">Websites</Link>

              <div className="navbar-item has-dropdown is-hoverable">
                <Link to="/people" className="navbar-link">People</Link>

                <div className="navbar-dropdown">
                  <Link to="/people/composers" className="navbar-item">Music</Link>
                  <Link to="/people/artists" className="navbar-item">Art</Link>
                  <Link to="/people/programmers" className="navbar-item">Code</Link>
                  <Link to="/people/designers" className="navbar-item">Design</Link>
                  <hr className="navbar-divider" />
                  <Link to="/add-yourself" className="navbar-item">Add Yourself</Link>

                </div>
              </div>
            </div>

            <div className="navbar-end">
            <div className="is-flex is-hidden-touch">
              <a href="https://twitter.com/robsgameshack" target="_blank" rel="noopener nofollow noreferrer" className="navbar-item is-primary is-size-4"><span className="icon"><i className="fab fa-twitter-square" /></span></a>
              <a href="https://discord.gg/NCDPH7s" target="_blank" rel="noopener nofollow noreferrer" className="navbar-item is-primary is-size-4" title="RGS Discord"><span className="icon"><i className='fab fa-discord'/></span></a>
            </div>
            <Link to="/add-yourself" className="navbar-item is-primary">Add Yourself</Link>
              <div className="navbar-item has-dropdown is-hoverable">
                
                <Link to="/about" className="navbar-link">About RGS</Link>

                <div className="navbar-dropdown">
                  {/* <Link to="/about-me" className="navbar-item">Who did this?</Link> */}
                  <Link to="/sponsorship" className="navbar-item">Sponsorship</Link>
                  <Link to="/jobs" className="navbar-item">Jobs</Link>
                  <Link to="/deals" className="navbar-item">Deals!</Link>
                </div>
              </div>
              <div className="navbar-item">
              <div className="is-flex is-hidden-desktop">
                <a href="https://twitter.com/robsgameshack" target="_blank" rel="noopener nofollow noreferrer" className="navbar-item is-primary is-size-4"><span className="icon"><i className="fab fa-twitter-square" /></span></a>
                <a href="http://discord.gg/WFck2gW" target="_blank" rel="noopener nofollow noreferrer" className="navbar-item is-primary is-size-4" title="GDL Discord"><span className="icon"><i className='fab fa-discord'/></span></a>
              </div>
                {/* <div className="buttons">
                  <a href="/" className="button is-primary">
                    <strong>Sign up</strong>
                  </a>
                  <a href="/" className="button is-light">Log in</a>
                </div> */}
              </div>
            </div>
          </div>
          </div>
        </nav>
    )
}
