import React, { Component } from 'react'
import SEO from '../components/elements/SEO'
import Loader from "../components/elements/Loader"
import Sharing from "../components/elements/Sharing"
//import Partner from "../assets/PartnerStampWhite.png"
import Store from "../assets/HumblePartner.png"
import uStore from "../assets/uas.png"
import Breadcrumbs from "../components/Breadcrumbs"
import Countdown from 'react-countdown';



export default class Deals extends Component {

    constructor() {
        super()

        this.state = {
            data: false
        }
    }

    componentDidMount() {

        let url = "https://data.robsgameshack.co.uk/wp-json/wp/v2/ad/?per_page=100&_embed=wp:term"

        fetch(url)
        .then(res => res.json())
        .then(res => {

            this.setState({data: res})

        })
        .catch(error => {

            console.log("Error", error)

        })

    }

    render() {

        let r = <Loader />

        if(this.state.data) {

            let sorted = this.state.data;

            sorted.sort((a, b) => a.acf.expires - b.acf.expires).reverse();

            let i = (

                this.state.data.map((el, index) => {

                    let item = []

                    let tags = el._embedded["wp:term"].map((tag) => {

    
                        let r = tag.map((item) => {
    
                            let r = "#"+item.slug.replace(/-/g, "")
    
                            return r
    
                        })
    
                        r = r.join(" ")
    
                        return r
    
                    })
        
                   tags.push("#RGSDeal #GamerDeals #Gaming\n\n")
        
                   tags = tags.join(" ");

                   let now = new Date().getTime() / 1000

                   let ava = (el.acf.expires && el.acf.expires > now) ? "is-success" : "is-danger",
                       dis = (el.acf.expires && el.acf.expires > now) ? false : true,
                       text = (el.acf.expires && el.acf.expires > now) ? "Get it" : "Missed it"

                    const deadline = new Date(el.acf.expires * 1000);

                    item = (
                        <div key={index} className="column is-full-mobile is-half-tablet is-3-desktop is-2-fullhd">
                            <div className="card">
                                <div className="card-image">
                                    <figure className="image is-16by9">
                                        <a href={el.acf.url} alt={el.acf.title}>
                                            <img src={el.acf.image.sizes.large} alt={el.acf.title} />
                                        </a>
                                    </figure>
                                    {el.acf.logo &&
                                    <div className="logo">
                                        <img src={el.acf.logo.sizes.large} alt={el.acf.title} />
                                    </div>
                                    }
                                </div>
                                <div className="card-content">
                                    <div className="content">
                                        <h6 dangerouslySetInnerHTML={{__html: el.title.rendered }}/>
                                        
                                        {dis !== true &&
                                        <div className="countdown notification is-warning has-text-centered p-1">
                                            <Countdown date={deadline} />
                                        </div>
                                        }
                                        
                                        <a href={el.acf.url} target="_blank" rel="noopener nofollow noreferrer" className={"button is-fullwidth "+ava} disabled={dis}>{text}</a>
                                    </div>
                                </div>
                                <footer className="card-footer">
                                    <div className="card-footer-item">
                                    <Sharing shareUrl={el.acf.url} title={el.acf.title} mobile={true} tags={tags} />
                                    </div>
                                </footer>
                            </div>
                        </div>
                    )

                   


                    return item


                })

            )


            r = (
                <div className="columns is-mobile is-multiline" data-masonry='{ "itemSelector": ".column" }'>
                    <div className="grid-sizer"></div>
                    {i}
                </div>
            )

        }

        return (
            <>
            <SEO
                title="Rob's Deals Page - Video Games and Game Development Software Deals from Acros the internet"
                description="Here you will find video game and game developemt software deals, bundles, asset packs and everything to help you either enjoy playing or making video games."
            >
                <link rel="canonical" href={"https://robsgameshack.com/people/deals"} />
            </SEO>
            <section className="hero is-success">
                <div className="hero-body">
                    <div className="container">
                        <div className="columns">
                            <div className="column is-three-quarters-desktop">
                                {/* <img src={Partner} alt="Humble Partnet" width="150" className="humbleBadge"/> */}
                                <h1 className="title">
                                    Rob's Deals! (Beta)
                                </h1>
                                <h2 className="subtitle">
                                    Some deals I have found from around the interwebs!
                                </h2>
                                <p>If like to help support this site, and you find something cool you like in the deals below - go for it! If it's a Humble Product you will also be supporting <a target="_blank" rel="noopener noreferrer" href="https://www.gamesaid.org/">Games Aid</a>, as it is my chosen Partner Charity.</p>
                            </div>
                            <div className="column">
                                <ul>
                                    <li>
                                        <a href="https://www.humblebundle.com/store?partner=robsgameshack&charity=31075" target="_blank" rel="noopener nofollow noreferrer" alt="Support Rob's Game Shack">
                                            <div className="has-text-centered has-text-white">Buy your games here!</div>
                                            <img src={Store} alt="Humble Store"/>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://assetstore.unity.com/?aid=1011l7soH" target="_blank" rel="noopener nofollow noreferrer" alt="Support Rob's Game Shack">
                                            <img src={uStore} alt="Unity Assets Store"/>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>     
                </div>
            </section>
            <Breadcrumbs />
            <section className="section pt-1">
                {r}
            </section>

            </>
        )
    }
}
