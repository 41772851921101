import React from 'react'

function checkString(string) {

    return string.includes("?")

}

export default function Funding(props) {

    let { kickstarter, indiegogo, patreon, kofi } = props.acf,
        r = (<></>)

    if(kickstarter || indiegogo || patreon || kofi) {

        if(kickstarter)
            kickstarter = (checkString(kickstarter)) ? kickstarter+"&"+props.link : kickstarter+"?"+props.link
        
        if(indiegogo)
            indiegogo = (checkString(indiegogo)) ? indiegogo+"&"+props.link : indiegogo+"?"+props.link

        if(patreon)    
            patreon = (checkString(patreon)) ? patreon+"&"+props.link : patreon+"?"+props.link

        if(kofi)    
            kofi = (checkString(kofi)) ? kofi+"&"+props.link : kofi+"?"+props.link

        r = (
        <div className="column is-half fundingBox">
        <h3 className="title is-3">Support Them!</h3>
        <ul className="level is-mobile">
            {kickstarter && 
                <li className="level-item">
                    <a href={kickstarter} alt={"Support " + props.title + " on KickStater"} title={"Support " + props.title + " on KickStater"} target="_blank" rel="noopener nofollow noreferrer">
                        <i className="fab fa-kickstarter k-green"></i>
                    </a>
                </li>
            }
            {indiegogo && 
                <li className="level-item">
                    <a href={indiegogo} alt={"Support " + props.title + " on Indiegogo"} title={"Support " + props.title + " on Indiegogo"} target="_blank" rel="noopener nofollow noreferrer">
                    <i className="fas fa-money-bill-wave"></i>
                    </a>
                </li>
            }
            {patreon && 
                <li className="level-item">
                    <a href={patreon} alt={"Support " + props.title + " on Patreon"} title={"Support " + props.title + " on Patreon"} target="_blank" rel="noopener nofollow noreferrer">
                        <i className="fab fa-patreon p-blue"></i>
                    </a>
                </li>
            }
            {kofi && 
                <li className="level-item">
                    <a href={kofi} alt={"Support " + props.title + " on Ko-Fi"} title={"Support " + props.title + " on Ko-Fi"} target="_blank" rel="noopener nofollow noreferrer">
                        <i className="fas fa-coffee kofi-red"></i>
                    </a>
                </li>
            }
        </ul>
        </div>
        )

    }



    return r
}
