import React from 'react';
import Header from '../components/Header';
import SEO from '../components/elements/SEO';


let organisation = `
{
  "@context": "https://schema.org",
  "@type": "Organization",
  "name": "Rob's Game Shack",
  "legalName" : "RSC Media Ltd",
  "url": "https://robsgameshack.com",
  "@id": "https://robsgameshack.com/#organization",
  "logo": "https://robsgameshack.com/logo512.png",
  "foundingDate": "2018",
  "founders": [
    {
    "@type": "Person",
    "name": "Rob MacKay"
    }
  ],
  "address": {
    "@type": "PostalAddress",
    "streetAddress": "4 North Guildry Street",
    "addressLocality": "Elgin",
    "addressRegion": "Moray",
    "postalCode": "IV30 1JR",
    "addressCountry": "UK"
  },
  "contactPoint": {
    "@type": "ContactPoint",
    "contactType": "PR Contact",
    "email": "info@robsgameshack.com"
  },
  "sameAs": [ 
    "https://www.twitter.com/robsgameshack"
  ]
}
`

const About = () => (
  <>
    <SEO 
      title="Video Games, Indie dev from the excellent community on Rob's Game Shack" 
      description="Independantly developed games created by the community." 
      keywords="Indiegames, gamedev, games, videogames"
    >
      <script type="application/ld+json">
        {organisation}
      </script>
      <link rel="canonical" href={"https://robsgameshack.com/about"} />
    </SEO>
    <Header title="You talking to me?" subTitle="Oh you are? Oh well stay a while and listen..." />
    <div className="container">
      <section className="section content"> 
      <h2>About RGS</h2>
      <p>Hello! I am Rob, owner of Rob's Game Shack. I am here to show the world that not all game stores are about fighting over an open game. My site is all about games and gamers! We are here to promote indie games and indie developers. We offer resources for game developers to help them create the best games, promote it to the world, and make their dream of running their own game studio come true.</p>

      <p>If you are an indie developer you can sign up and add yourself, your skills and your project to the site for all to find.  You will be able to add all your links to help people find your content including fundraising!  The Project pages also support a press kit feature giving you a linkable press kit page that you can link to from your own site, storing all your important information to be shown to the press and the world!</p>

      <p>All of this is free for you to use so sign up and become part of the network!</p>
      <h2>Get subscribed to our newsletter for awesome stuff!</h2>
      <div id="revue-embed">
        <form action="https://www.getrevue.co/profile/robsgameshack/add_subscriber" method="post" id="revue-form" name="revue-form"  target="_blank">
        <div className="field revue-form-group">
          <label for="member_email" className="label">Email address</label>
          <div className="control">
            <input className="revue-form-field input" placeholder="Your email address..." type="email" name="member[email]" id="member_email" required/>
          </div>
        </div>
        <div className="field is-grouped revue-form-group">
            <div className="control is-expanded">
              <label className="label" for="member_first_name">First name <span className="optional">(Optional)</span></label>
              <input className="revue-form-field input" placeholder="First name... (Optional)" type="text" name="member[first_name]" id="member_first_name" />
            </div>
            <div className="control is-expanded">
              <label className="label" for="member_last_name">Last name <span className="optional">(Optional)</span></label>
              <input className="revue-form-field input" placeholder="Last name... (Optional)" type="text" name="member[last_name]" id="member_last_name" />
            </div>
        </div>
        <div className="field revue-form-actions">
        <div className="control">
          <input type="submit" value="Subscribe" className='button is-link' name="member[subscribe]" id="member_submit" />
          </div>
        </div>
        <div className="revue-form-footer is-size-7 has-text-centered">By subscribing, you agree with Revue’s <a target="_blank" href="https://www.getrevue.co/terms" rel="noopener noreferrer">Terms of Service</a> and <a target="_blank" href="https://www.getrevue.co/privacy" rel="noopener noreferrer">Privacy Policy</a>.</div>
        </form>
      </div>
      </section>
    </div>
  </>
);

export default About;
