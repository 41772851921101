import React from 'react'
import Loader from './Loader';
import { Link } from 'react-router-dom';

let cleanString = (string) => {

    return string.replace("&#8217;", "'");

}

export default function ItemsList(props) {

    let r = <Loader />

    let ref = "?utm_source=robsgameshack&utm_medium=listcard"

    if(props.data) {

        if(props.type === "person")
            props.data.reverse()

        let m = props.data.map((el, index) => {

            let thumb = (el.acf.background_image) ? el.acf.background_image.sizes.thumbnail : false
            let type = props.type

            if(thumb === false)
                thumb = (el.acf.avatar) ? el.acf.avatar.sizes.thumbnail : false


            if(props.type === "person") {
                type = 'people'
            }

            if(props.type === "project") {
                type = 'games'
            }

            return (

                <div className="box" key={index}>
                    <article className="media">
                        {thumb && 
                        <div className="media-left">
                        <figure className="image is-96x96">
                            <Link to={"/"+type+"/"+el.slug} alt={"Read about "+ cleanString(el.title.rendered)}>
                                <img src={thumb} alt={cleanString(el.title.rendered)} />
                            </Link>
                        </figure>
                        </div>
                        }
                        <div className="media-content">
                        <div className="content">
                            <p><strong><Link to={"/"+type+"/"+el.slug} alt={"Read about "+ cleanString(el.title.rendered)}>{cleanString(el.title.rendered)}</Link></strong></p>
                            <p>{el.acf.short_description}</p>
                        </div>
                        <nav className="level is-mobile">
                            <div className="level-left">
                            {el.acf.twitter &&
                            <a href={"https://twitter.com/"+el.acf.twitter+ref} alt={"Twitter - " + el.acf.twitter} target="_blank" rel="noopener nofollow noreferrer" className="level-item" >
                                <span className="icon is-small">
                                    <i className="fab fa-twitter"></i>
                                </span>
                            </a>
                            }
                            {el.acf.instagram &&
                            <a href={"https://instagram.com/"+el.acf.instagram+ref} alt={"Instagram - " + el.acf.instagram} target="_blank" rel="noopener nofollow noreferrer" className="level-item">
                                <span className="icon is-small">
                                    <i className="fab fa-instagram"></i>
                                </span>
                            </a>
                            }
                            {el.acf.itch &&
                            <a href={el.acf.itch+ref} alt={cleanString(el.title.rendered) + " on Itch"} target="_blank" className="level-item" rel="noopener nofollow noreferrer">
                                <span className="icon is-small">
                                    <i className="fab fa-itch-io"></i>
                                </span>
                            </a>
                            }
                            {el.acf.bandcamp &&
                            <a href={el.acf.bandcamp+ref} alt={cleanString(el.title.rendered) + " on bandcamp"} target="_blank" className="level-item" rel="noopener nofollow noreferrer">
                                <span className="icon is-small">
                                <   i className="fab fa-bandcamp"></i>
                                </span>
                            </a>
                            }
                            {el.acf.sound_cloud &&
                            <a href={el.acf.sound_cloud+ref} alt={cleanString(el.title.rendered) + " on Sound Cloud"} target="_blank" className="level-item"  rel="noopener nofollow noreferrer">
                                <span className="icon is-small">
                                <   i className="fab fa-soundcloud"></i>
                                </span>
                            </a>
                            }
                            {el.acf.website &&
                            <a href={el.acf.website+ref} alt={cleanString(el.title.rendered) + "'s Website"} target="_blank" className="level-item"  rel="noopener nofollow noreferrer">
                                <span className="icon is-small">
                                    <i className="fas fa-external-link-square-alt"></i>
                                </span>
                            </a>
                            }
                            </div>
                        </nav>
                        </div>
                    </article>
                </div>

            )


        })

        r = (
            <div className={"column "+ props.width} >
                {props.title && 
                <h3 className="title is-3">{props.title}</h3>
                }   
                {m}
            </div>

        )

    }


    return r

}
