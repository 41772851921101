import React, { Component } from 'react'
import SEO from '../components/elements/SEO'

export default class AddYou extends Component {
    render() {
        return (
            <>
            <SEO 
                title="Your turn, add yourself to Rob's Game Shack here" 
                description="Add yourself, your project, your team or your website to Rob's Game Shack for free listing and promotion! Come join the gang!" 
                keywords="Indiegames, gamedev, games, videogames"
            >
                <link rel="canonical" href={"https://robsgameshack.com/add-yourself"} />
            </SEO>
            <div className="container">
                <section className="section mainbody">
                <div className="content">
                <h1>Tell me about yourself</h1>
                <p>This section is going to be developed heavily but for now if you could follow this link and let me know about you that would be awesome!</p>
                <p>If you would like to add yourself, the button below will take you to a wordpress sign up page, where you can, once signed up and logged in, add yourself by going to the people option in the left hand menu.  You can also add Teams or Projects etc - basically have a look!</p>
                <p>After you have added your info I will have a look and approve it.</p>
                <p>You can even add posts if you so wish!  Thinking about Press releases, News, Tutorials, etc - all things that are related to you.</p>
                <p>You can update your information at anytime, all information will be owned by you and you can delete it at anytime too.</p>
                <p><a className="button is-primary" href="https://data.robsgameshack.co.uk/wp-login.php?action=register" target="_blank" rel="noopener nofollow noreferrer">Register Here!</a></p>
                </div>
                </section>
                <div className="columns">
                </div>
            </div>
            </>
        )
    }
}
