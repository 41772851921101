import React from 'react'
import { Adsense } from '@ctrl/react-adsense'

export default function Ad() {

    return (
        <section className="section">
            <div className="container">
                <Adsense 
                    client='ca-pub-6732159348639168'
                />
            </div>
        </section>
    )
}
