import React from 'react'
import rgslogo from '../assets/rgslogo.svg'
import SearchHome from "./elements/SearchHome"
import Store from "../assets/HumblePartner.png"
import uStore from "../assets/uas.png"
import AdBox from "./elements/AdBox"
import { useLocation } from "react-router-dom"

export default function Header(props) {
    
    let {title = "Welcome to Rob's Game Shack!", subTitle = "Here to help you suceed in your Game Development Career."} = props,
        r,
        location = useLocation() 

    if(location.pathname === "/") {

        r = <SearchHome />

    } else {

        r = (
            <>
            <section className="hero is-medium is-primary">
                <img src={rgslogo} className="bgimage" alt="RGS" />
                    <div className="hero-body">
                        <div className="container">
                            <div className="columns">
                                <div className="column is-10">
                                    <h1 className="title">
                                        {title}
                                    </h1>
                                    <h2 className="subtitle">
                                        {subTitle}
                                    </h2>
                                </div>
                                <div className="column box has-background-info-dark">
                                <h3 className="title is-5 has-text-centered">Quick Affiliates</h3>
                                <ul>
                                    <li>
                                        <a href="https://www.humblebundle.com/store?partner=robsgameshack&charity=31075" target="_blank" rel="noopener nofollow noreferrer" alt="Support Rob's Game Shack">
                                            <div className="has-text-centered has-text-white">Buy your games here!</div>
                                            <img src={Store} alt="Humble Store"/>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://assetstore.unity.com/?aid=1011l7soH" target="_blank" rel="noopener nofollow noreferrer" alt="Support Rob's Game Shack">
                                            <img src={uStore} alt="Unity Assets Store"/>
                                        </a>
                                    </li>
                                </ul>
                                </div>
                            </div>
                        </div>
                    </div>
            </section>
            <AdBox />
            </>
        )

    }

    return r
}
