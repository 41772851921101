import React from 'react';
import Breadcrumbs from '../components/Breadcrumbs';
import Header from '../components/Header';
import ItemCol from '../components/ItemCol';
import SEO from '../components/elements/SEO';

const Music = () => (
  <>
    <SEO 
      title="Video Games Composers, Musicians, Sound FX Designers from the Game Dev community on Rob's Game Shack" 
      description="It should sound great as well, here is a great collection of tallented sound related creative people..." 
      keywords="Indiegames, gamedev, games, videogames"
    >
      <link rel="canonical" href={"https://robsgameshack.com/people/composers"} />
    </SEO>
    <Header title="VG Musicians" subTitle="Adding beats, rhythem and those cool death sound effects" />
      <div className="container">
      <Breadcrumbs />
      <ItemCol number="100" title="Musicians" type="person" filter="14" layout="cards"/>
    </div>
  </>
);

export default Music;
