import React from 'react';
import Header from '../components/Header';
//import Items from '../components/elements/Items'
import ItemCol from '../components/ItemCol';
import SEO from '../components/elements/SEO';
import Breadcrumbs from '../components/Breadcrumbs';

const Website = () => (
  <>
    <SEO 
      title="Websites relating to the Independant Game Development Community on Rob's Game Shack" 
      description="Websites containing information, tutorials, news, tips, jams and much more can be found here." 
      keywords="Indiegames, gamedev, games, videogames"
    >
      <link rel="canonical" href={"https://robsgameshack.com/website"} />
    </SEO>
    <Header title="Websites" subTitle="From learning &amp; resources to cool community shops!" />
    <div className="container">
      <Breadcrumbs />
      <ItemCol title="Websites" type="website" number="100" layout="cards" />
    </div>
  </>
);

export default Website;
