import React from 'react'
import { Helmet } from "react-helmet";
import ReactGA from 'react-ga';
import { useLocation } from "react-router-dom"

export default function SEO(props) {

    let location = useLocation();

    let { title = "Rob's Game Shack - Game Dev and Indie Game support and community", 
          seo_description = "The place to find Indie Games, Indie Developers and to Create connections and most importantly make games!",
          description,
          keywords, 
          twitter = "robsgameshack", 
          image = "https://robsgameshack.com/logo512.png",
    } = props
    
    
    seo_description = (description) ? description : seo_description

    twitter = (twitter) ? twitter : "robsgameshack"

    if(location.hostname !== "localhost")
        ReactGA.pageview(location.pathname + location.search);
    
    return (
        <Helmet>
            {title &&
            <title>{title}</title>
            }
            {seo_description &&
            <meta name="description" content={seo_description} />
            }
            {keywords &&
            <meta name="keywords" content={keywords} />
            }
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:site" content="@robsgameshack" />
            <meta name="twitter:title" content={title} />
            <meta name="twitter:description" content={seo_description} />
            <meta name="twitter:creator" content={"@"+twitter} />
            <meta name="twitter:image:alt" content={title} />
            <link rel="alternate" hreflang="en" href={location.href} />
            <link rel="alternate" hreflang="x-default" href={location.href} />
            {image && 
            <meta name="twitter:image" content={image} />
            }
            {image && 
            <meta property="og:image" content={image} />
            }
            <meta property="og:url" content={location.href} />
            <meta property="og:type" content="application/javascript" />
            <meta property="og:title" content={title} />
            <meta property="og:description" content={seo_description} />
            {props.children}
        </Helmet>
    )
}
