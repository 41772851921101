import React from 'react';
import Header from '../components/Header';
import SEO from '../components/elements/SEO';
import AdBox from '../components/elements/AdBox';


const Sponsorship = () => {

  let boxStyle = {
    border: 'none',
    width: '100%',
    padding: '4px',
    background: '#FFFFFF'
  }
  
  return (
  <>
    <SEO 
      title="Sponsor Rob's Game Shack" 
      description="Independantly developed games created by the community." 
      keywords="Indiegames, gamedev, games, videogames"
    >
      <link rel="canonical" href={"https://robsgameshack.com/sponsorship"} />
    </SEO>
    <Header title="Sponsor the future!" subTitle="You want to help? Well read about the many ways you can!" />
    <div className="container">
      <div className="columns">
        <div className="column is-9">    
          <section className="section content">
            <h2>Helping RGS</h2>
            <p>The goal of RGS is to promote everyone involved in the creation of indie games, and their pets.  I want RGS to be a place to celebrate the creativity, talent and hard work of Indie game creators.</p>
            <p>Initially I will be looking for writers for the site, from general reporting of news, interviews and general information on specific games.</p>
            <div class="notification is-success"><span className="subtitle is-h3">I don't believe in "exposure" as payment. If someone does something for the site, I want to pay them fairly.</span></div>
            <h2>But I do need help...</h2>
            <p className="subtitle">So what can you do?</p>
            <ul>
              <li>Sharing, liking, commenting. Spreading the news about the site and its people is priceless.</li>
              <li>Whitelist RGS in your ad blocker.  I only have banner ads and don't use any pop up crap...</li>
              <l1>Buy any games you buy via my affiliate links! see below!</l1>
              <li>Second is I want the site to be financially independent, to do this full time. I hope to achieve this with using affiliation and adverts on the site also gaining sponsorship. I have also opened a ko-fi account to accept one time and monthly donations.</li>
            </ul>  
            <h2>Affiliate Links:</h2>
            <p>Any purchase you make will directly help the site!</p>
            <ul>
              <li><a href="https://www.kqzyfj.com/click-100347237-13212017" target="_top">View</a> awesome game deals from Fanatical!<img src="https://www.lduhtrp.net/image-100347237-13212017" width="1" height="1" border="0" alt=""/></li>
              <li><a href="https://www.kqzyfj.com/click-100347237-10912384" target="_top">Green Man Gaming</a><img src="https://www.tqlkg.com/image-100347237-10912384" width="1" height="1" border="0"  alt=""/></li>
              <li><a href="https://www.humblebundle.com/store?partner=robsgameshack&charity=31075">Humble Bundle Store</a></li>
              <li><a href="https://assetstore.unity.com/?aid=1011l7soH">Unity Assets Store</a></li>
            </ul>
            <h2>Help me help you!</h2>
            <p className="subtitle">Quick Recap:</p>
            <div class="notification is-success">
              <ul>
                <li>Like, Share, Subscribe &amp; use the site.</li>
                <li>Sign up if you are involved in any way!</li>
                <li>Use my affiliate links to buy your assets and games.</li>
                <li>Donate directly and have my eternal gratitude!</li>
                <li>Subscribe and unlock special subscriber features, like giveaways and fun things to be decided.</li>
                <li>Become a sponsor and have that known to all the visitors!</li>
              </ul>
            </div>
            <p>So if you can do any of these things, that would be amazing - use my affiliate links to Humble, Unity and Green Man Gaming. Share lots of things and maybe even donate if you can!</p>
            <iframe src='https://ko-fi.com/robsgameshack/?hidefeed=true&widget=true&embed=true&preview=true' style={boxStyle} height='712' title='robsgameshack' />                     
          </section>
        </div>
        <div className="column is-hidden-touch">
          <AdBox verticle />
        </div>
      </div>
      <AdBox />
    </div>
  </>
)};

export default Sponsorship;
