import React from 'react'
import { Link } from "react-router-dom"
import { Helmet } from 'react-helmet'


export default function Breadcrumbs(props) {

    let url = (window.location.pathname !== "/") ? window.location.pathname.split("/") : false;

    let r = "",
        max = url.length - 1,
        classes = (props.className) ? " " + props.className : "",
        structuredData = {
            "@context": "https://schema.org",
            "@type": "BreadcrumbList",
            "itemListElement": []
        }

    if(url) {

        r = (
            <div className={"container"}>
                <nav className={"breadcrumb" + classes} aria-label="breadcrumbs">
                    <ol>
                        {
                            url.map((el, index) => {

                                let burl = "/",
                                    bname = "Home",
                                    active = "",
                                    current = ""
                                    

                                if(index === 1) { 
                                    burl = "/"+el
                                    bname = el.replace(/-/g, " ")
                                } else if(index > 1) {

                                    burl = "/"+url[1]+"/"+url[2]
                                    bname = el.replace(/-/g, " ")

                                }

                                if(max === index) {
                                    active = "is-active"
                                    current = "page"
                                    bname = (props.title) ? props.title.rendered : bname
                                }

                                structuredData.itemListElement.push({

                                    "@type": "ListItem",
                                    "position": index + 1,
                                    "name": bname,
                                    "item": "https://"+ window.location.host + burl

                                })

                                return ( 
                                        <li className={active} key={index}>
                                            {max === index 
                                             ? <span dangerouslySetInnerHTML={{__html: bname}} style={{padding: "0px 12px"}} />
                                             : <Link to={burl} aria-current={current}><span dangerouslySetInnerHTML={{__html: bname}} /></Link>
                                            }
                                        </li>
                                    ) 
                            })
                        }
                    </ol>
                </nav>
                <Helmet>
                    <script type="application/ld+json">
                        {JSON.stringify(structuredData)}
                    </script>
                </Helmet>
            </div>
        )

    }

    return r
}
