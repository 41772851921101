import React from 'react'

export default function AdBox(props) {

    const   {children, verticle} = props

    let classes = "container has-text-centered mt-5",
        content = ""
 
    if(verticle){
        classes = "container has-text-centered is-hidden-mobile"
    }

    const ads = [
        {   
            link:   "https://www.jdoqocy.com/click-100347237-14459448",
            linkm:  "https://www.tkqlhce.com/click-100347237-14459442",
            img:    "https://www.lduhtrp.net/image-100347237-14459448",
            imgm:   "https://www.awltovhc.com/image-100347237-14459442",
            target: "_top",
            text:   "Humble Bundle - Shop Thousands of Games with sales happening everyday!",
        },
        {   
            link:   "https://www.awin1.com/cread.php?s=2699495&v=20517&q=395579&r=938227",
            linkm:  "https://www.awin1.com/cread.php?s=2699497&v=20517&q=395580&r=938227",
            img:    "https://www.awin1.com/cshow.php?s=2699494&v=20517&q=395578&r=938227",
            imgm:   "https://www.awin1.com/cshow.php?s=2699497&v=20517&q=395580&r=938227",
            text:   "Dino PC custom gaming PCs"
        },
        {   
            link:   "https://www.jdoqocy.com/click-100347237-14034498",
            linkm:  "https://www.jdoqocy.com/click-100347237-14034496",
            img:    "https://www.awltovhc.com/image-100347237-14034498",
            imgm:   "https://www.ftjcfx.com/image-100347237-14034496",
            target: "_top",
            text:   "Fanatical.com - Big savings on official Steam games",
        },
        {   
            link:   "https://prf.hn/click/camref:1011l7soH/creativeref:1011l62230",
            linkm:  "https://prf.hn/click/camref:1011l7soH/creativeref:1101l62303",
            img:    "https://creative.prf.hn/source/camref:1011l7soH/creativeref:1011l62230",
            imgm:   "https://creative.prf.hn/source/camref:1011l7soH/creativeref:1101l62303",
            target: "_top",
            text:   "Unity Assets Store Summer Sale!",
        }
    ]

    const verts = [
        {
            link:   "https://prf.hn/click/camref:1011l7soH/creativeref:1011l61488",
            img:    "https://creative.prf.hn/source/camref:1011l7soH/creativeref:1011l61488",
            text:   "Unity for Pros",
            target: "_top",
        },
        {
            link:   "https://www.dpbolvw.net/click-100347237-13218036",
            img:    "https://www.tqlkg.com/image-100347237-13218036",
            text:   "Fanatical.com - Big savings on official Steam games",
            target: "_top",
        },
        {
            link:   "https://www.anrdoezrs.net/click-100347237-15024687",
            img:    "https://www.awltovhc.com/image-100347237-15024687",
            text:   "Consumer Electronics & Tech Gadgets EU Sale",
            target: "_top",
        },
        {
            link:   "https://prf.hn/click/camref:1011l7soH/creativeref:1101l66372",
            img:    "https://creative.prf.hn/source/camref:1011l7soH/creativeref:1101l66372",
            text:   "Unity Asset Refresh Sale!",
            target: "_top",
        },
        {
            link:   "https://prf.hn/click/camref:1011l7soH/creativeref:1011l62233",
            img:    "https://creative.prf.hn/source/camref:1011l7soH/creativeref:1011l62233",
            text:   "Ukraine Mega Bundle",
            target: "_top",
        }
    ]

    if(children) {
        content = children
    } else {
        if(verticle) {

            let vert = verts[Math.floor(Math.random() * verts.length)];

            if(vert.link) {
            content = (
                    <a href={vert.link} target="_top">
                        <img src={vert.img} width="auto" height="auto" alt={vert.text} rel="sponsored" border="0"/>
                    </a>
            )
            } else {
                content = ""
            }

        } else {
           
                let ad = ads[Math.floor(Math.random() * ads.length)];

                if(window.innerWidth >= 600) {
                    if(ad.link) {
                    content = (
                                <a href={ad.link} target="_top">
                                    <img src={ad.img} width="auto" height="auto" alt={ad.text} border="0"/>
                                </a>
                            )
                    } else {
                        content = ""
                    }

                } else {

                    if(ad.linkm) {
                    content = (
                        <a href={ad.linkm} target="_top">
                            <img src={ad.imgm} width="auto" height="auto" alt={ad.text} border="0"/>
                        </a>
                    )
                    } else {
                        content = ""
                    }

                }
                
            
        }
    }

    return (
        <div className={classes}>
            {content}
        </div>
    )
}
