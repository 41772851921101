import React, { Component } from 'react'
import Loader from './Loader'

export default class RichTxt extends Component {
    constructor(props){
        super(props)

        this.state = {
            data: props.data,
            width: (props.width) ? props.width : ""
        }
        
    }

    componentDidUpdate(nextProps) {

        if(nextProps.data !== this.props.data) {

            let newState = { data: this.props.data }

            this.setState(newState)

        }

    }

    render() {

        let r = <Loader />

        if(this.state.data) {

            r = (
                <div className={"column article "+ this.state.width}>
                        <div className="content">
                            <div dangerouslySetInnerHTML={{__html: this.state.data }} />
                        </div>
                </div>
            )

        }

        return r
    }

    static defaultProps = {
        location: window.location
      }
}
