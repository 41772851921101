import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import castle from "../../assets/castle.svg"
import cloud from "../../assets/cloud.svg"
import { Helmet } from 'react-helmet'
import AdBox from './AdBox'


export default class SearchHome extends Component {

    constructor(props){
        super()

        let string = (props.search) ? props.search.replace("?s=", "") : false

        this.state = {
            results: false,
            search: false,
            string: string
        }

        this.timer = null
        this.controller = new AbortController();
    }

    search = (e) => {

            if(e) {
                e.persist()
            }

            clearTimeout(this.timer)

            let s = (this.state.string && !e) ? encodeURI(this.state.string) : encodeURI(e.target.value)

            let url = "https://data.robsgameshack.co.uk/wp-json/wp/v2/search/?per_page=5&subtype=post,person,team,project,website,software&search="+s,
                text = document.querySelector(".fadeOut"),
                head = document.querySelector(".WelcomeSearch .hero-head"),
                field = document.querySelector(".searchBox"),
                graphics = document.querySelector(".graphics")

                field.classList.add("is-loading")

            this.timer = setTimeout(() => {

                        if(s) {
                            text.classList.add("is-hidden-mobile")
                            head.classList.add("is-hidden-mobile")
                            graphics.classList.add("is-hidden")
                            fetch(url, {signal: this.controller.signal})
                            .then(res => res.json())
                            .then(res => {
                                
                                this.setState({results: res, search: s})
                                field.classList.remove("is-loading")
                        })

                        } else {
                            this.setState({results: false, search: false})
                            field.classList.remove("is-loading")
                            graphics.classList.remove("is-hidden")
                            text.classList.remove("is-hidden-mobile")
                            head.classList.remove("is-hidden-mobile")
                        }

                        
                        

                    }, 1500)
    }

    componentDidMount() {

        if(this.state.string)
            this.search()

        document.querySelector(".searchBox input").value = (this.state.string) ? this.state.string : ""

    }

    componentWillUnmount()
    {
        this.controller.abort();
    }

    render() {

        let r,
            jsonSD = `
                {
                "@context": "https://schema.org",
                "@type": "WebSite",
                "url": "https://robsgameshack.com/",
                    "potentialAction": {
                        "@type": "SearchAction",
                        "target": "https://robsgameshack.com/?s={search_term_string}",
                        "query-input": "required name=search_term_string"
                    }
                }
            `

        if(this.state.results && this.state.results.length > 0) {

            

            let convert = {
                project: "games",
                person: "people",
                team: "team",
                software: "software",
                website: "website",
                post: "news"
            }
            

            r = this.state.results.map((el, index) => {
                
                let slug = el.url.split("/")

                slug = slug.filter(function (el) {
                    return el !== "";
                  });

                let link = convert[el.subtype] + "/" + slug.pop()

                return (
                    <div key={index} className="box m-1">
                        <Link to={link} className="is-bold" dangerouslySetInnerHTML={{ __html: el.title }} />
                    </div>
                )
                
            })

        } else if(this.state.results.length === 0) {

            let fun = [
                "You got nothin'!",
                "Ain't no sunshine...",
                "Are you spelling that right?",
                "At least you tried...",
                "YOU SHALL NOT PAAAAAAAAASS!",
                "I still love you",
                "This is probably your fault",
                "YOU WILL NOT SHALL NOT PAASS!",
                "Well this is awkward.",
                "Do you smell burning?",
                "AJ? Are you still trying?",
                "Sometimes nothing is the right answer."
            ]

            let result = fun[Math.floor(Math.random() * fun.length)]

            r = (
                <div className="box m-1">
                    <p className="has-text-centered">{result}</p>
                </div>
            )
            
        }

        return (
            <>
            <section className="hero is-fullheight-with-navbar WelcomeSearch">
                <Helmet>
                <script type="application/ld+json">
                    {jsonSD}
                </script>
                </Helmet>
                <div className="hero-head">
                    {window.innerWidth >= 600 &&
                    <AdBox />
                    }
                </div>
                <div className="hero-body">
                    <div className="container">
                        <div className="columns is-multiline">
                            <div className="column is-12 content mb-0">
                                <h1 className="has-text-weight-normal">Promoting &amp; Connecting the Indie Dev Community.</h1>
                            </div>
                            <div className="column is-4 content fadeOut">
                                
                                <p className="mb-0">Greetings Traveller!</p> 
                                <p className="is-hidden-touch">You want to find something? Well you have come to the right place!</p>
                                <p className="mb-0">Stay a while and listen...</p> 
                                <p className="is-hidden-touch mb-0">A world of magicians, gods and muses can be found just beyond the mystical word portal over there...</p>

                            </div>
                            <div className="column is-4 has-text-centered">
                                <div className="graphics is-hidden-mobile">
                                    <img src={castle} alt="Castle" className="castle" layout="fill" />
                                    <img src={cloud} alt="Cloud" className="cloud" layout="fill" />
                                    <img src={cloud} alt="Cloud" className="cloud2" layout="fill" />
                                </div>
                                <div className="field">
                                    <div className="control is-large searchBox">
                                        <input className="input is-large is-rounded" type="text" placeholder="What do you seek?" onChange={this.search}  />
                                    </div>
                                </div>
                                <div className="results">
                                    {r}
                                </div>
                            </div>
                            <div className="column is-4 content">
                                
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {window.innerWidth < 600 &&
            <AdBox />
            }
            </>
        )
    }
}
