import React, { Component } from 'react'
import Loader from './Loader'

export default class EmbedPosts extends Component {
    constructor(props) {
        super(props)

        this.state = {
            data: false,
            json: props.json,
            ref: "?utm_source=robsgameshack&utm_medium=homepage_postfeed",
            width: props.width,
            title: (props.title) ? props.title : false,
            no_excerpt: (props.excerpt) ? true : false,
            no: (props.count) ? props.count : "5"
        }
    }

    componentDidMount() {

        let url = this.state.json + "wp/v2/posts?_fields=title,excerpt,link,date&per_page="+this.state.no,
            title = this.state.json.split("/")[2]

            title = (this.state.title) ? this.state.title : "Direct from" + title.charAt(0).toUpperCase() + title.slice(1)


        fetch(url)
        .then(res => res.json())
        .then(res => this.setState({data: res, title: title}))

    }

    render() {

        let r = <Loader width="is-half"/>

        if(this.state.data) {

            r = this.state.data.map((el, index) => {

                let time = el.date.split("T")[0];

                let b = (
                    <li key={index} className="content">
                        <div>
                        <h4><a href={el.link + this.state.ref} alt={el.title.rendered} dangerouslySetInnerHTML={{__html: el.title.rendered }} target="_blank" rel="noopener noreferrer" /></h4>
                        <small className="date is-size-7">{time}</small>
                        </div>
                        {this.state.no_excerpt &&
                            <div dangerouslySetInnerHTML={{__html: el.excerpt.rendered}} />
                        }
                        <div className="has-text-right">
                            <a href={el.link + this.state.ref} alt={el.title.rendered} target="_blank" rel="noopener nofollow noreferrer">Keep Reading...</a>
                        </div>
                    </li>
                )

                return b

            })

            r = (
                <div className={"embed-posts column " + this.state.width}>
                    <h4 className="title is-4">{this.state.title}</h4>
                    <ul className="box">{r}</ul>
                </div>
            )

        }

        return r
    }
}
