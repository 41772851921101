import React from 'react'

export default function Video(props) {
    
    let { id, width } = props,
        r,
        format = (props.format) ? props.format : false


    if(format === "vimeo") {

        r = <iframe className="has-ratio" title="Trailer" src={"https://player.vimeo.com/video/"+id} width="640" height="360" frameBorder="0" allow="autoplay; fullscreen" allowFullScreen></iframe>

    } else {

        r = <iframe className="has-ratio" title="Trailer" width="560" height="315" src={"https://www.youtube.com/embed/"+id} frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
    }

    

    
    return (   
        <div className={"column "+width}>
            <figure className="image is-16by9">
              {r}  
            </figure>
        </div>
    )
}
