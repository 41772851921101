import React from 'react'

export default function Loader(props) {

    let title = (props.title) ? props.title : "",
        width = (props.width) ? props.width : "is-full",
        inner,
        light = (props.light) ? " has-text-white" : ""

    if(width === "is-full") {

        inner = "is-one-quarter"

    } else {

        inner = "is-four-fifths"

    }

    return (
        <div className={"column " + width + light} >
                <h3 className="is-size-3 title">{title}</h3>
                <div className="columns is-multiline">
                    <div className="column">

                        <div className="box">

                            <div className="content">
                            <div className="columns is-centered">
                                <div className={"column has-text-centered " + inner}>
                                    <h3 className={"title is-3" + light}>Welcome To RGS</h3>
                                    <p>We are getting your content... should only be a moment... you look great by the way!</p>
                                    <progress className="progress is-small is-primary" max="100">15%</progress>
                                </div>
                            </div>
                            </div>

                        </div>

                    </div>
                </div>
            </div>
    )
}
