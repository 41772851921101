import React, { Component } from 'react'

class ReloadModal extends Component {
    state = {
      show: false
    };
    componentDidMount() {
      // Handle global event.
      window.addEventListener("newContentAvailable", () => {
        this.setState({
          show: true
        });
      });
    }
    onClick = () => {
      // Reload when modal click.
      document.location.reload(true);
    };
    render() {
      if (!this.state.show) {
        return null;
      }
      // <Modal> is common fixed component.
      return (
        <div id="modal" className="modal is-active" onClick={this.onClick}>
            <div className="modal-background"></div>
            <div className="modal-content">
                <div className="content has-text-centered">
                    <p>The App has been updated, Click to refresh!</p>
                    <button className="button is-success" >Update the App</button>
                </div>
            </div>
        </div>
      );
    }
  }

  export default ReloadModal