import React from 'react';
//import Items from "../components/elements/Items"
import Header from '../components/Header';
import ItemCol from '../components/ItemCol';
import SEO from '../components/elements/SEO';
//import EmbedPosts from '../components/elements/EmbedPosts';

let organisation = `
{
  "@context": "https://schema.org",
  "@type": "Organization",
  "name": "Rob's Game Shack",
  "legalName" : "RSC Media Ltd",
  "url": "https://robsgameshack.com",
  "@id": "https://robsgameshack.com/#organization",
  "logo": "https://robsgameshack.com/logo512.png",
  "foundingDate": "2018",
  "founders": [
    {
    "@type": "Person",
    "name": "Rob MacKay"
    }
  ],
  "address": {
    "@type": "PostalAddress",
    "streetAddress": "4 North Guildry Street",
    "addressLocality": "Elgin",
    "addressRegion": "Moray",
    "postalCode": "IV30 1JR",
    "addressCountry": "UK"
  },
  "contactPoint": {
    "@type": "ContactPoint",
    "contactType": "PR Contact",
    "email": "info@robsgameshack.com"
  },
  "sameAs": [ 
    "https://www.twitter.com/robsgameshack",
    "https://www.instagram.com/robsgameshack",
    "https://www.twitter.com/robsgameshack",
    "https://www.twitch.tv/robsgameshack",
    "https://robsgameshack.itch.io/"
  ]
}
`

const Home = () => (
  <>
    <SEO 
      title="Rob's Game Shack - The Independant Game Development Community!" 
      description="Supporting the Independant Development Creative Community, connecting and promoting the work of the people of the Video Game community." 
      keywords="Indiegames, gamedev, games, videogames" 
    > 
      <script type="application/ld+json">
        {organisation}
      </script>
      <link rel="canonical" href={"https://robsgameshack.com"} />
    </SEO>
    <Header />
    <section className="section">
      <div className="container">
        <div className="columns is-multiline">
          {/* <EmbedPosts json="http://data.robsgameshack.co.uk/wp-json/" width="is-half" title="Latest Posts" />
          <EmbedPosts json="https://blog.humblebundle.com/wp-json/" width="is-half" title="Humble Bundle Blog" excerpt={false} count={3} />  */}
          <ItemCol type="project" title="Latest Projects" layout="cards"/>
          <ItemCol type="person" title="Latest People" layout="cards" />
          <ItemCol type="website" title="Latest Websites" layout="cards"/>
          <ItemCol type="team" title="Latest Studios" layout="cards"/>
        </div>
      </div>
    </section>
  </>
);

export default Home;
