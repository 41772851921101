import React, { Component } from 'react'
import Loader from '../components/elements/Loader'
import SEO from '../components/elements/SEO'

export default class PressKit extends Component {

    constructor(props) {
        super()

        let slug = props.location.pathname.split("/")

        this.state = {
            data: false,
            slug: slug[2]
        }

        this.controller = new AbortController();

    }

    componentDidMount() {

        let url = "https://data.robsgameshack.co.uk/wp-json/wp/v2/project/?slug="+this.state.slug+"&_fields=acf"

        fetch(url, {signal: this.controller.signal})
        .then(res => res.json())
        .then(res => this.setState({data: res[0].acf}))
        .catch(error => {

            console.log("Error", error)

        })

    }

    componentWillUnmount(){
        this.controller.abort();
    }

    render() {

        let r = <Loader />

        if(this.state.data) {

            if(this.state.data.pk_title) {

            let {
                pk_title, 
                pk_price, 
                pk_release_date,
                pk_estimated_release,
                pk_platforms, 
                pk_game_site, 
                pk_description, 
                pk_files, 
                pk_quotes, 
                pk_social_media_links, 
                pk_credits, 
                pk_company_name, 
                pk_company_address, 
                pk_company_phone, 
                pk_company_site, 
                pk_email, 
                pk_engine__tech,
                background_image
            } = this.state.data

            let platforms = (
                    <ul className="social">   
                        {pk_platforms.map((el,index) => (

                            <li key={index}>{el.label}</li>

                        ))
                        }
                    </ul>
            )

            r = (
                <>
                <SEO
                 title={pk_title + " Press Kit"} 
                 description={"Details for the game "+pk_title+" and company details for "+pk_company_name} 
                 keywords="Indiegames, gamedev, games, videogames"
                 image={background_image.sizes.large}             
                >
                    <link rel="canonical" href={"https://robsgameshack.com/games/"+this.state.slug+"/presskit/"} />
                </SEO>
                <div className="columns is-multiline">
                    <div className="column is-full flex-first">
                        <h1 className="pb-3 is-size-1 pk_title">{pk_title + " Press Kit"}</h1>
                    </div>
                    <div className="column is-8 flex-third">
                        <div dangerouslySetInnerHTML={{__html: pk_description}} />
                        {pk_credits &&
                        <>
                        <h3>Credits</h3>
                        <div dangerouslySetInnerHTML={{__html: pk_credits}} />
                        </>
                        }
                        {pk_quotes && 
                            <>
                            <h3>Opinions</h3>
                            <div>
                            {pk_quotes.map((el,index) => {

                                return (
                                    <div key={index} className="card my-5">
                                        <div className="card-content rounded">
                                            <em>{el.quote}</em>
                                            <div className="has-text-right is-size-7">
                                                <small>{el.from}</small>
                                            </div>
                                        </div>
                                    </div>
                                )
                                

                            })
                            }
                            </div>
                            </>
                        }
                    </div>
                    <div className="column is-4 flex-second">
                        <div className="table__wrapper">
                            <div className="table-container mb-5 is-fullwidth">
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th colSpan="2">
                                                <h3 className="mb-1">Game Details</h3>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <th>Title:</th>
                                            <td>{pk_title}</td>
                                        </tr>
                                        {pk_price &&
                                        <tr>
                                            <th>Price:</th>
                                            <td>{pk_price}</td>
                                        </tr>
                                        }
                                        {pk_release_date &&
                                        <tr>
                                            <th>Release Date:</th>
                                            <td>{pk_release_date}</td>
                                        </tr>
                                        }
                                        {pk_estimated_release && !pk_release_date &&
                                        <tr>
                                            <th>Release Date:</th>
                                            <td>{pk_estimated_release}</td>
                                        </tr>
                                        }
                                        {pk_game_site &&
                                        <tr>
                                            <th>Website:</th>
                                            <td><a href={pk_game_site} rel="nofollow">{pk_game_site}</a></td>
                                        </tr>
                                        }
                                        {pk_platforms && 
                                        <tr>
                                            <th>Platforms:</th>
                                            <td>{platforms}</td>
                                        </tr>
                                        }
                                        {pk_engine__tech && 
                                        <tr>
                                            <th>Engine:</th>
                                            <td>{pk_engine__tech}</td>
                                        </tr>
                                        }
                                        {pk_social_media_links && 
                                        <tr>
                                            <th>Social Media:</th>
                                            <td>
                                            <ul className="social">
                                            {pk_social_media_links.map((el,index) => {

                                                return (
                                                    <li key={index} ><a href={el.pk_sm_url} alt="Social Media" rel="nofollow">{el.pk_sm_url.replace(/http(s)?:\/\/(www\.)?/g, "")}</a></li>
                                                )
                                                
                                            })
                                            }
                                            </ul>
                                            </td>
                                        </tr>   
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        {pk_company_name &&
                        <div className="table__wrapper">
                        <div className="table-container mb-5">
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th colSpan="2">
                                            <h3 className="mb-1">Company Details</h3>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {pk_company_name &&
                                    <tr>
                                        <th>Title:</th>
                                        <td>{pk_company_name}</td>
                                    </tr>
                                    }
                                    {pk_email && 
                                    <tr>
                                        <th>Email:</th>
                                        <td><a href={`mailto:${pk_email}`} alt="email" rel="nofollow">{pk_email}</a></td>
                                    </tr>
                                    }
                                    {pk_company_address && 
                                    <tr>
                                        <th>Address:</th>
                                        <td dangerouslySetInnerHTML={{__html: pk_company_address}} />
                                    </tr>
                                    }
                                    {pk_company_site &&
                                    <tr>
                                        <th>Website:</th>
                                        <td><a href={pk_company_site} rel="nofollow">{pk_company_site}</a></td>
                                    </tr>
                                    }
                                    {pk_company_phone &&
                                    <tr>
                                        <th>Phone:</th>
                                        <td>{pk_company_phone}</td>
                                    </tr>
                                    }
                                </tbody>
                            </table>
                        </div>
                        </div>
                        }
                        {pk_files &&
                        <div className="table__wrapper">
                        <div className="table-container mb-5">
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th colSpan="2">
                                            <h3 className="mb-1">Assets</h3>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>
                                            <a href={pk_files} className="button is-success is-fullwidth" target="_blank" rel="noopener nofollow noreferrer" alt="Presskit Files">
                                                <span className="icon">
                                                    <i className="far fa-file-image" />
                                                </span>
                                                <span>Images &amp; Assets</span>
                                            </a>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        </div>
                        }
                    </div>
                </div>   
                </>
            )

            
            } else {

                r = (
                    <div className="content has-text-centered">
                    <h1>Looks like there is no Presskit here yet!</h1>
                    <p>Presskits are a great way to get clean and precise content about your game out to publishers and news outlets</p>
                    <p>It's simple to set up - get in touch! <a href="https://twitter.com/robsgameshack" rel="nofollow">@RobsGameShack</a></p>
                    </div>
                )

            }


        }

        return (
            <section className="section mainbody has-background-primary">
                <div className="container">
                    <div className="box content">
                        {r}
                    </div>
                </div>
            </ section>
        )
    }
}

