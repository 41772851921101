import React from 'react';
import { createBrowserHistory } from "history"
import { Router } from "react-router-dom"
import Routes from "./Routes"
import Nav from "./components/Nav"
import Footer from "./components/Footer"
import ScrollToTop from './extras/ScrollToTop'
import CookieConsent from "react-cookie-consent";
import ReactGA from "react-ga"


//import rgslogo from './assets/rgslogo.svg';
import './style.sass'
import './extras/scripts'

var hist = createBrowserHistory()

ReactGA.initialize('UA-139126896-3');

function App() {
  return (
    <Router history={hist}>
      <ScrollToTop />
      <div className="App">
        <Nav />
        <Routes />
        <Footer />
      </div>
      <CookieConsent 
        buttonClasses="button is-success"
        buttonWrapperClasses="cookie-button-wrapper"
        disableButtonStyles
        disableStyles
      >
        This website uses cookies to enhance the user experience. Some Google Analytics stuff mostly. Basically the normal style of cookies that make your site work. :-)
      </CookieConsent>
    </Router>
  );
}

export default App;
