import React from 'react'
import {
    //FacebookMessengerShareButton,
    FacebookShareButton,
    //FacebookMessengerIcon,
    TwitterShareButton,
    TelegramShareButton,
    WhatsappShareButton,
    RedditShareButton,
    TumblrShareButton,
    FacebookIcon,
    TwitterIcon,
    TelegramIcon,
    WhatsappIcon,
    RedditIcon,
    TumblrIcon,
} from 'react-share'

let cleanString = (s) => {

    return s.replace("&#8217;", "'");

}

export default function Sharing(props) {

    let { shareUrl, title, width, tags, handle } = props,
    mobile = (props.mobile) ? " is-mobile" : ""
 
    title = cleanString(title)
    handle = (handle) ? "@"+handle+" " : ""
    tags = tags + " "

    let fun = [
        "Autobots roll out! ",
        "Wow this is cool ",
        "Read this... ",
        "Look at this ",
        "Nice! ",
        "WoW! ",
        "Holy shirt balls! ",
        "I'm awesome so take this ",
        "It's dangerous to go alone, take this... ",
        "You'll never guess what they did next... ",
        "Sharing is caring, ",
        "All your bases are belong to us... "
    ]

    let text = fun[Math.floor(Math.random() * fun.length)]

    return (
        <div className={"level"+mobile+ " " +width}>
            <div className="level-item">
                <FacebookShareButton
                    url={shareUrl}
                    quote={text + title + tags}
                >
                    <FacebookIcon size={32} round />
                </FacebookShareButton>
            </div>

            {/* <div className="level-item">
                <FacebookMessengerShareButton
                    url={shareUrl}
                    appId="521270401588372"
                >
                    <FacebookMessengerIcon size={32} round />
                </FacebookMessengerShareButton>
            </div> */}
            
            <div className="level-item">
                <TwitterShareButton
                    url={shareUrl}
                    title={text + title + " " + handle + tags}
                >
                    <TwitterIcon size={32} round />
                </TwitterShareButton>
            </div>

            <div className="level-item">
                <RedditShareButton
                    url={shareUrl}
                    title={title}
                    windowWidth={660}
                    windowHeight={460}
                >
                    <RedditIcon size={32} round />
                </RedditShareButton>
            </div>

            <div className="level-item">
                <TumblrShareButton
                    url={shareUrl}
                    title={title}
                >
                    <TumblrIcon size={32} round />
                </TumblrShareButton>
            </div>


            <div className="level-item">
                <TelegramShareButton
                    url={shareUrl}
                    title={title}
                >
                    <TelegramIcon size={32} round />
                </TelegramShareButton>
            </div>

            <div className="level-item">
                <WhatsappShareButton
                    url={shareUrl}
                    title={title}
                    separator=":: "
                >
                    <WhatsappIcon size={32} round />
                </WhatsappShareButton>
            </div>

        </div>
    )
}
