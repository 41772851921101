import React from 'react'
import { Link } from 'react-router-dom'
import Loader from "./Loader"
import ph from "../../assets/1by1ph.png"
import Image from 'react-graceful-image'

let cleanString = (string) => {

    return string.replace("&#8217;", "'");

}

export default function Items(props) {

        let r, 
            col = "is-4-tablet is-2-desktop",
            ref = "?utm_source=robsgameshack&utm_medium=card"

        if(props.width === "is-half")
            col = "is-4"

            if(props.data) {
                    

                r = (
                    <div className={"column infodisplay " + props.width}>
                            <h3 className="is-size-3 title">{props.title}</h3>
                            <div className="columns is-multiline">
                            
                            {props.data.map((el, index) => {

                                let img, title, slug, ratio, subtitle, width, height, background = false, styles = {}, imgpad = "0"

                                if(props.type === "person") {
                                    img = (el.acf.avatar) ? el.acf.avatar.sizes['rgs-1by1-cropped'] : ph
                                    width = (el.acf.avatar) ? el.acf.avatar.sizes['rgs-1by1-cropped-width'] : 575;
                                    height = (el.acf.avatar) ? el.acf.avatar.sizes['rgs-1by1-cropped-height'] : 575;
                                    ratio = "is-1by1"
                                    title = (el.acf.preferred_name) ? el.acf.preferred_name : el.acf.first_name //+ " " + el.acf.second_name
                                    slug = "/people/"+el.slug
                                    subtitle = (el.acf.title) ? el.acf.title : el._embedded["wp:term"][0][0].name
                                }

                                if(props.type === "project") {
                                    background = (el.acf.background_image) ? el.acf.background_image.sizes['rgs-1by1-cropped'] : ph
                                    img = (el.acf.project_logo) ? el.acf.project_logo.sizes['rgs-logo'] : false
                                    width = (el.acf.project_logo) ? el.acf.project_logo.sizes['rgs-logo-width'] : 575;
                                    height = (el.acf.project_logo) ? el.acf.project_logo.sizes['rgs-logo-height'] : 575;
                                    ratio = "is-1by1"
                                    title = cleanString(el.title.rendered)
                                    slug = "/games/"+el.slug
                                    subtitle = "Status: "+ el.acf.status
                                }

                                if(props.type === "team") {
                                    img = (el.acf.logo) ? el.acf.logo.sizes['rgs-logo'] : false
                                    ratio = "is-1by1"
                                    title = cleanString(el.title.rendered)
                                    slug = "/team/"+el.slug
                                    background = (el.acf.backaground) ? el.acf.backaground.sizes['rgs-1by1-cropped'] : ph
                                    width = (el.acf.avatar) ? el.acf.logo.sizes['rgs-logo-width'] : "auto";
                                    height = (el.acf.avatar) ? el.acf.logo.sizes['rgs-logo-height'] : "auto";
                                    imgpad = "1rem"
                                }

                                if(props.type === "website") {
                                    img = (el.acf.logo) ? el.acf.logo.sizes['rgs-logo'] : ph
                                    ratio = "is-1by1"
                                    title = cleanString(el.title.rendered)
                                    slug = "/website/"+el.slug
                                    background = (el.acf.backaground_image) ? el.acf.backaground_image.sizes['rgs-1by1-cropped'] : ph
                                    width = (el.acf.avatar) ? el.acf.backaground.sizes['rgs-1by1-cropped-width'] : 575;
                                    height = (el.acf.avatar) ? el.acf.backaground.sizes['rgs-1by1-cropped-height'] : 575;
                                }

                                if(background) {
                                    styles = {
                                        backgroundColor: 'transparent',
                                        backgroundRepeat: "no-repeat",
                                        backgroundPosition: "center center",
                                        backgroundSize: "cover",
                                        position: "relative"
                                    }
                                }

                                let buttonText = props.type.charAt(0).toUpperCase() + props.type.slice(1) + " Details"

                                return (

                                    <div className={"column "+col} key={index}>

                                        <div className="card items">
                                            <div className="card-image">
                                                <figure className={"image " + ratio} style={styles}>
                                                    <Link to={slug}>
                                                        <Image src={background} alt={title} width={575} height={575} className="bgabso"/>
                                                    </Link>
                                                </figure>
                                                {img &&
                                                <div className="p-1">
                                                    <Link to={slug} className="img-flex" style={{padding:imgpad}}>
                                                        <Image src={img} alt={title} width={width} height={height} className="logotop" style={{height: height}} />
                                                    </Link>
                                                </div>
                                                }
                                                <ul className="level is-mobile funding">
                                                    {el.acf.kickstarter && 
                                                        <li className="level-item">
                                                            <a href={el.acf.kickstarter+ref} alt={"Support " + title + " on KickStater"} title={"Support " + title + " on KickStater"} target="_blank" rel="noopener nofollow noreferrer">
                                                                <i className="fab fa-kickstarter k-green"></i>
                                                            </a>
                                                        </li>
                                                    }
                                                    {el.acf.indiegogo && 
                                                        <li className="level-item">
                                                            <a href={el.acf.indiegogo+ref} alt={"Support " + title + " on Indiegogo"} title={"Support " + title + " on Indiegogo"} target="_blank" rel="noopener nofollow noreferrer">
                                                            <i className="fas fa-money-bill-wave"></i>
                                                            </a>
                                                        </li>
                                                    }
                                                    {el.acf.patreon && 
                                                        <li className="level-item">
                                                            <a href={el.acf.patreon+ref} alt={"Support " + title + " on Patreon"} title={"Support " + title + " on Patreon"} target="_blank" rel="noopener nofollow noreferrer">
                                                                <i className="fab fa-patreon p-blue"></i>
                                                            </a>
                                                        </li>
                                                    }
                                                    {el.acf.kofi && 
                                                        <li className="level-item">
                                                            <a href={el.acf.kofi+ref} alt={"Support " + title + " on Ko-Fi"} title={"Support " + title + " on Ko-Fi"} target="_blank" rel="noopener nofollow noreferrer">
                                                                <i className="fas fa-coffee kofi-red"></i>
                                                            </a>
                                                        </li>
                                                    }
                                                </ul>
                                            </div>
                                            <div className="card-content padding-1">
                                                <div className="titlebox">
                                                    <h4 className="title is-5 is-marginless"><Link to={slug} dangerouslySetInnerHTML={{__html: title}}/></h4>
                                                    {subtitle &&
                                                    <small>{subtitle}</small>
                                                    }
                                                </div>
                                            </div>
                                            <div>
                                            <nav className="level is-mobile has-background-light is-marginless">
                                                {el.acf.twitter &&
                                                <div className="level-item has-text-centered">
                                                    <span className="icon has-text-info">
                                                        <a href={"https://twitter.com/"+el.acf.twitter+ref} alt={"Twitter - " + el.acf.twitter} target="_blank" rel="noopener nofollow noreferrer">
                                                        <i className="fab fa-twitter"></i>
                                                        </a>
                                                    </span>
                                                </div>
                                                }
                                                {el.acf.instagram &&
                                                <div className="level-item has-text-centered">
                                                    <span className="icon has-text-info">
                                                    <a href={"https://instagram.com/"+el.acf.instagram+ref} alt={"Instagram - " + el.acf.instagram} target="_blank" rel="noopener nofollow noreferrer">
                                                        <i className="fab fa-instagram"></i>
                                                    </a>
                                                    </span>
                                                </div>
                                                }
                                                {el.acf.youtube &&
                                                <div className="level-item has-text-centered">
                                                    <span className="icon has-text-info">
                                                    <a href={el.acf.youtube+ref} alt={title + " on Youtube"} target="_blank" rel="noopener nofollow noreferrer">
                                                        <i className="fab fa-youtube"></i>
                                                    </a>
                                                    </span>
                                                </div>
                                                }
                                                {el.acf.steam_page && 
                                                <div className="level-item has-text-centered">
                                                    <span className="icon has-text-info">
                                                    <a href={el.acf.steam_page+ref} alt={title + " on Steam"} target="_blank" rel="noopener nofollow noreferrer">
                                                        <i className="fab fa-steam"></i>
                                                    </a>
                                                    </span>
                                                </div>
                                                }
                                                {el.acf.itch && 
                                                <div className="level-item has-text-centered">
                                                    <span className="icon has-text-info">
                                                    <a href={el.acf.itch+ref} alt={title + " on Itch"} target="_blank" rel="noopener nofollow noreferrer">
                                                        <i className="fab fa-itch-io"></i>
                                                    </a>
                                                    </span>
                                                </div>
                                                }
                                                {el.acf.website &&
                                                <div className="level-item has-text-centered">
                                                    <span className="icon has-text-info">
                                                    <a href={el.acf.website+ref} alt={title + "'s Website"} target="_blank" rel="noopener nofollow noreferrer">
                                                        <i className="fas fa-external-link-square-alt"></i>
                                                    </a>
                                                    </span>
                                                </div>
                                                }
                                                
                                            </nav>
                                            <Link to={slug} className="button is-primary is-fullwidth" alt="Read More">{buttonText}</Link>
                                            </div>
                                        </div>

                                    </div>

                                )


                            })}
                            </div>
                    </div>
                )

            } else {

                r = <Loader title={props.title} />

            }

        return r

}

