import React from 'react'
import SEO from '../components/elements/SEO'
import Header from '../components/Header'
import { Link } from 'react-router-dom'

export default function NotFound() {
    return (
        <>
        <SEO
            title="404: Page Not Found" 
            description="Unfortunatly we could not find your Indie Game Page!" 
            >
            <link rel="canonical" href={"https://robsgameshack.com/404"} />
        </SEO>
        <Header title="404: Page Not Found" subTitle="Unfortunatly we could not find your Indie Game Page!" />
        <div className="container">
            <section className="section content"> 
            <h2>Sorry! Looks like something went wrong!</h2>
            <p>Probably a bug, or the feature creep monster attacking from the shadows! Look out! I see him looking at your project!</p>
            <p>But what can you do?</p>
            <ul>
                <li><Link to="/">Go to the Home Page</Link></li>
                <li><Link to="/news">Check out an article</Link></li>
                <li><Link to="/games">Browse the Games</Link></li>
                <li><Link to="/people">Look at some people</Link></li>
            </ul>
            </section>
        </div>
    </>
    )
}
