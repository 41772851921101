import React from 'react'

export default function SoundCloudPlayer(props) {
    return (
        <div className="column is-half">
            <iframe title={props.title + "on Soundcloud"} width="100%" height="450" scrolling="no" frameBorder="no" allow="autoplay" src={"https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/users/"+props.link+"&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true"}></iframe>
            <div style={{fontSize: "10px", color: "#cccccc", lineBreak: "anywhere", wordBreak: "normal", overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis", fontFamily: "sans-serif", fontWeight: "100"}} >
                <a href={props.soundlink} title={props.title} target="_blank" rel="noopener nofollow noreferrer" style={{color: "#cccccc", textDecoration: "none"}}>{props.title}</a>
            </div>
        </div>
    )
}
