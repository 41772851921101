import React from 'react';
import Breadcrumbs from '../components/Breadcrumbs';
import Header from '../components/Header';
import ItemCol from '../components/ItemCol';
import SEO from '../components/elements/SEO';

const People = () => (
  <>
    <SEO 
      title="The People who make the Games, Programmers, Designers, Composers, Artists & Writers" 
      description="A big collection of People behind some of the greatest Indie Games of our Community, you'll find them all here" 
      keywords="Indiegames, gamedev, games, videogames"
    >
      <link rel="canonical" href={"https://robsgameshack.com/people"} />
    </SEO>
    <Header title="The People. You." subTitle="Some think they are going crazy, but there are actual going sane in a crazy world..." />
    <div className="container">
      <Breadcrumbs />
      <ItemCol number="50" title="The Awesome People" type="person" layout="cards"/>
    </div>
  </>
);

export default People;
