import React, { Component } from 'react'
import ItemsList from './elements/ItemsList'
import Items from './elements/Items'

export default class ItemCol extends Component {
    constructor(props) {
        super()

        let type = (props.type) ? props.type : "post",
            title = (props.title) ? props.title : "",
            num = (props.number) ? props.number : "6",
            filt = (props.filter) ? "&filter="+props.filter : "",
            ids = (props.ids) ? props.ids : false,
            width = (props.width) ? props.width : "is-full",
            layout = (props.layout) ? props.layout : "list"
 
        this.state = {
            data: false,
            type: type,
            title: title,
            num: num,
            filt: filt, 
            ids: ids,
            width: width,
            layout: layout
        }

        this.controller = new AbortController();
    }

    componentDidMount(){

        let url = "https://data.robsgameshack.co.uk/wp-json/wp/v2/"+this.state.type+"?_embed&per_page="+this.state.num+this.state.filt

        if(this.state.ids)
            url = "https://data.robsgameshack.co.uk/wp-json/wp/v2/"+this.state.type+"?_embed&include="+this.state.ids


        fetch(url, {signal: this.controller.signal})
        .then(res => res.json())
        .then(res => this.setState({data: res}))
        .catch(error => console.log("Error", error))

    }

    componentDidUpdate(nextProps){


        if(nextProps.location.pathname !== this.props.location.pathname) {

            let type = (nextProps.type) ? nextProps.type : "post",
                title = (nextProps.title) ? nextProps.title : "",
                num = (nextProps.number) ? nextProps.number : "6",
                filt = (nextProps.filter) ? "&filter="+nextProps.filter : "",
                ids = (nextProps.ids) ? nextProps.ids : false,
                width = (nextProps.width) ? nextProps.width : "",
                layout = (nextProps.layout) ? nextProps.layout : "list"

            let newState = this.state;

            newState = {
                data: false,
                type: type,
                title: title,
                num: num,
                filt: filt, 
                ids: ids,
                width: width,
                layout: layout
            }

            let url = "https://data.robsgameshack.co.uk/wp-json/wp/v2/"+newState.type+"?_embed&per_page="+newState.num+newState.filt

            if(newState.ids)
                url = "https://data.robsgameshack.co.uk/wp-json/wp/v2/"+newState.type+"?_embed&include="+newState.ids

            fetch(url, {signal: this.controller.signal})
            .then(res => res.json())
            .then(res => {
                newState.data = res
                this.setState(newState)
            })
            .catch(error => console.log("Error", error))
        }


    }

    componentWillUnmount()
    {
        this.controller.abort()
    }

    render() {
        
        let r

        if(this.state.layout === "cards") {
            r = <Items {...this.state} />
        } else {
            r = <ItemsList {...this.state} />
        }

        return r;

    }

}

ItemCol.defaultProps = {
    location: window.location.pathname
}
