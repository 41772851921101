import React, { Component } from 'react'
import Loader from '../components/elements/Loader'
import { Link, Redirect } from 'react-router-dom'
import RichTxt from '../components/elements/RichTxt'
import Video from '../components/elements/Video'
import ItemCol from '../components/ItemCol'
import Twitter from '../components/elements/Twitter'
import SEO from '../components/elements/SEO'
import EmbedPosts from '../components/elements/EmbedPosts'
import SoundCloudPlayer from '../components/elements/SoundCloudPlayer'
import EmbedItch from '../components/elements/EmbedItch'
import EmbedSteam from '../components/elements/EmbedSteam'
import Funding from "../components/elements/Funding"
import Sharing from "../components/elements/Sharing"
import Breadcrumbs from '../components/Breadcrumbs'
import AdBox from "../components/elements/AdBox"

//import SEO from '../components/elements/SEO'

export class Single extends Component {

    constructor(props){
        super(props)

        let slug = this.props.location.pathname.split("/").pop(),
            type = this.props.location.pathname.split("/")[1]

        let convert = {
            games: "project",
            people: "person",
            team: "team",
            software: "software",
            website: "website"
        }

        let breadcrumb = [
            "Home",
            type,
            slug
        ]

        this.state = {
            data: false,
            type: convert[type],
            slug: slug,
            breadcrumb: breadcrumb,
            ref: "?utm_source=robsgameshack&utm_medium=singlepage"
        }

        this.controller = new AbortController();

    }


    componentDidMount(){

        let url = "https://data.robsgameshack.co.uk/wp-json/wp/v2/"+this.state.type+"/?_embed&slug="+this.state.slug

        fetch(url, {signal: this.controller.signal})
        .then(res => res.json())
        .then(res => {
            this.setState({data: res[0]})
        })
        .catch(error => {

            console.log("Error", error)

        })

    }

    componentDidUpdate(nextProps) {

        if(nextProps.location.pathname !== this.props.location.pathname) {

            let slug = this.props.location.pathname.split("/").pop(),
                type = this.props.location.pathname.split("/")[1]

            let convert = {
                games: "project",
                people: "person",
                team: "team",
                software: "software",
                website: "website"
            }

            let newState = this.state;

            newState = {
                data: false,
                type: convert[type],
                slug: slug
            }

            let url = "https://data.robsgameshack.co.uk/wp-json/wp/v2/"+newState.type+"/?_embed&slug="+newState.slug

            fetch(url, {signal: this.controller.signal})
            .then(res => res.json())
            .then(res => {
                newState.data = res[0]
                this.setState(newState)
            })

        }

    }

    componentWillUnmount() 
    {
        this.controller.abort();
    }

    cleanString(string) {

        return string.replace("&#8217;", "'");

    }

    checkString(string) {

        return string.includes("?")

    }

    render() {

        let r = <Loader />,
            jsonSD = false

        if(this.state.data === undefined)
            r = <Redirect to="/404" />

        if(this.state.data) {

            let image = "",
                logo = false,
                timg = "",
                cleanTitle = (this.state.data.acf.preferred_name) ? this.state.data.acf.preferred_name : this.cleanString(this.state.data.title.rendered)

            if(this.state.data.acf.avatar) {
                image = this.state.data.acf.avatar.url
                timg = this.state.data.acf.avatar.sizes['rgs-2by1-cropped']
            } else if(this.state.data.acf.background_image) {
                image = this.state.data.acf.background_image.sizes.large
                timg = this.state.data.acf.background_image.sizes['rgs-2by1-cropped']
            } else if(this.state.data.acf.backaground) {
                image = this.state.data.acf.backaground.sizes.large
                timg = this.state.data.acf.backaground.sizes['rgs-2by1-cropped']
            }

            if(this.state.data.acf.logo)
                logo = this.state.data.acf.logo.sizes.medium

            if(this.state.data.acf.project_logo)
                logo = this.state.data.acf.project_logo.sizes['rgs-logo']

            let styles = {
                backgroundImage: `url(${image})`,
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center center",
                backgroundSize: "cover",
                width: "100%",
                height: "100%",
                position: "absolute",
                top: "0",
                left: "0",
                bottom: "0",
                right: "0",
                opacity: "0.3"
            }

            let projects, people, websites, loc = "From", intro = "Check out ", outro = " on Rob's Game Shack"

            if(this.state.data.acf.projects_linked) {
                projects = this.state.data.acf.projects_linked.map(el => el.ID)
            }

            if(this.state.data.acf.people_linked) {
                people = this.state.data.acf.people_linked.map(el => el.ID)
            }

            if(this.state.data.acf.websites_linked) {
                websites = this.state.data.acf.websites_linked.map(el => el.ID)
            }

            if(this.state.data.type === "project") {
                loc = "By"
            }

            let tags = this.state.data._embedded['wp:term'][0].map(el => {

                let r = "#"+el.slug.replace(/-/g, "")

                return r

            })

            tags.push("#RGSFeat #IndieDev\n\n")

            tags = tags.join(" ");

            let title = (logo) ? <img src={logo} alt={cleanTitle} title={cleanTitle} /> : cleanTitle;
            let auth = (this.state.data.acf.twitter) ? this.state.data.acf.twitter : "robsgameshack";
            let keywords = (this.state.data.acf.keywords) ? this.state.data.acf.keywords : cleanTitle;
            let description = (this.state.data.acf.seo_description) ? this.state.data.acf.seo_description : this.state.data.acf.short_description;
            let sub = ""

            if(this.state.data.type === "person") {

                sub = (this.state.data.acf.title) ? "- "+ this.state.data.acf.title + " -" : ""

                jsonSD = `
                {
                "@context": "https://schema.org",
                "@type": "Person",
                "email": "${this.state.data.acf.email}",
                "image": "${image}",
                "jobTitle": "${this.state.data.acf.title}",
                "name": "${cleanTitle}",
                "url": "${this.state.data.acf.website}",
                "sameAs" : [ 
                    "http://twitter.com/${this.state.data.acf.twitter}"
                ]
                }
            `

                jsonSD = (
                    <script type="application/ld+json">
                        {jsonSD}
                    </script>
                )



            }

            projects = (projects) ? projects.join() : false;
            people = (people) ? people.join() : false;
            
            let url = this.props.location.pathname

            r = (
                <>
                <SEO 
                    title={`${cleanTitle} ${sub} on Rob's Game Shack`}
                    seo_description={description}
                    keywords={keywords + ", Indiegames, gamedev, games, videogames"}
                    image={timg}
                    twitter={auth}
                >
                    {jsonSD}
                    <link rel="canonical" href={`https://robsgameshack.com${url}`} />
                </SEO>
                <section className="hero is-primary is-medium" style={{position:"relative"}}>
                    <div style={styles} />
                    {this.state.data.acf.pk_title &&
                        <div className="hero-head">
                            <div className="container has-text-right">
                                <Link to={`${url}/presskit`} className="button is-primary mt-2">
                                    <span className="icon"><i className="fas fa-info-circle"></i></span>
                                    <span>Press Kit</span>
                                </Link>
                            </div>
                        </div>        
                    }
                    <div className="hero-body">
                        <div className="container">
                        <h1 className="title is-1">
                            {title}
                        </h1>
                        {this.state.data.acf.title &&
                            <h2 className="subtitle" dangerouslySetInnerHTML={{__html: this.state.data.acf.title}} />
                        }
                        {this.state.data.acf.teams_linked &&
                        <h2 className="subtitle">
                            {loc} <Link to={"/team/"+this.state.data.acf.teams_linked[0].post_name} >{this.state.data.acf.teams_linked[0].post_title}</Link>
                        </h2>
                        }
                        {this.state.data.acf.short_description &&
                        <div className="columns">
                            <div className="column is-half content" dangerouslySetInnerHTML={{__html: this.state.data.acf.short_description}} />
                        </div>
                        }
                        </div>
                    </div>
                    <div className="hero-foot">
                    <nav className="tabs is-boxed is-fullwidth">
                    <div className="container">
                        <ul className="links">
                        {this.state.data.acf.twitter &&
                        <li className="has-text-centered">
                            <a href={"https://twitter.com/"+this.state.data.acf.twitter+this.state.ref} alt={"Twitter - " + this.state.data.acf.twitter} target="_blank" rel="noopener nofollow noreferrer">
                                <i className="fab fa-twitter"></i>
                            </a>
                        </li>
                        }
                        {this.state.data.acf.instagram &&
                        <li className="has-text-centered">
                            <a href={"https://instagram.com/"+this.state.data.acf.instagram+this.state.ref} alt={"Instagram - " + this.state.data.acf.instagram} target="_blank" rel="noopener nofollow noreferrer">
                                <i className="fab fa-instagram"></i>
                            </a>
                        </li>
                        }
                        {this.state.data.acf.youtube &&
                        <li className="has-text-centered">
                            <a href={this.state.data.acf.youtube+this.state.ref} alt={title + " on Youtube"} target="_blank" rel="noopener nofollow noreferrer">
                                <i className="fab fa-youtube"></i>
                            </a>
                        </li>
                        }
                        {this.state.data.acf.steam_page &&
                        <li className="has-text-centered">
                            <a href={this.state.data.acf.steam_page+this.state.ref} alt={title + " on Itch"} target="_blank" rel="noopener nofollow noreferrer">
                                <i className="fab fa-steam"></i>
                            </a>
                        </li>
                        }
                        {this.state.data.acf.itch &&
                        <li className="has-text-centered">
                            <a href={this.state.data.acf.itch+this.state.ref} alt={title + " on Itch"} target="_blank" rel="noopener nofollow noreferrer">
                                <i className="fab fa-itch-io"></i>
                            </a>
                        </li>
                        }
                        {this.state.data.acf.bandcamp &&
                        <li className="has-text-centered">
                            <a href={this.state.data.acf.bandcamp+this.state.ref} alt={title + " on bandcamp"} target="_blank" rel="noopener nofollow noreferrer">
                                <i className="fab fa-bandcamp"></i>
                            </a>
                        </li>
                        }
                        {this.state.data.acf.sound_cloud &&
                        <li className="has-text-centered">
                            <a href={this.state.data.acf.sound_cloud+this.state.ref} alt={title + " on Sound Cloud"} target="_blank" rel="noopener nofollow noreferrer">
                                <i className="fab fa-soundcloud"></i>
                            </a>
                        </li>
                        }
                        {this.state.data.acf.website &&
                        <li className="has-text-centered">
                            <a href={this.state.data.acf.website+this.state.ref} alt={title + "'s Website"} target="_blank" rel="noopener nofollow noreferrer">
                                <i className="fas fa-external-link-square-alt"></i>
                            </a>
                        </li>
                        }
                        </ul>
                    </div>
                    </nav>
                </div>
                </section>
                <AdBox />
                <Breadcrumbs title={this.state.data.title}/>
                <section className="section pt-0">
                <div className="container">

                    <div className="columns">
                    
                        <div className="column is-9">

                            <div className="columns is-multiline">

                            <Funding acf={this.state.data.acf} title={cleanTitle} link="utm_source=robsgameshack&utm_medium=singlepage"/>
                            
                            {this.state.data.acf.itch && this.state.data.acf.itch_id &&
                                <EmbedItch title={this.state.data.title.rendered} id={this.state.data.acf.itch_id} link={this.state.data.acf.itch} />
                            }
                            {this.state.data.acf.steam_id &&
                                <EmbedSteam id="steam_id" />
                            }
                            {this.state.data.acf.trailer && 
                                <Video id={this.state.data.acf.trailer} width="is-full" className="first"/>
                            }
                            {this.state.data.acf.vimeo && 
                                <Video id={this.state.data.acf.vimeo} format="vimeo" width="is-full" className="first"/>
                            }
                            {this.state.data.acf.sound_cloud_user_id &&
                                <SoundCloudPlayer link={this.state.data.acf.sound_cloud_user_id} soundlink={this.state.data.acf.sound_cloud} title={title}/>
                            }
                            {this.state.data.acf.more_infomation &&
                                <RichTxt data={this.state.data.acf.more_infomation} width="is-full"/>
                            }
                            {projects &&
                                <ItemCol ids={projects} type="project" title="Games" layout="list" width="is-half"/>
                            }
                            {websites &&
                                <ItemCol ids={websites} type="website" title="Connected to..." layout="list" width="is-half"/>
                            }
                            {people &&
                                <ItemCol ids={people} type="person" title="The Team" layout="list" width="is-half"/>
                            }
                            {this.state.data.acf.twitter && 
                                <Twitter handle={this.state.data.acf.twitter} width="is-half" />
                            }
                            {this.state.data.acf.embedFeeds && 
                                <EmbedPosts json={this.state.data.acf.embedFeeds} width="is-half" />
                            }
                            {this.state.data.acf.robs_thoughts &&
                                <div className="column box content article is-full" dangerouslySetInnerHTML={{__html: this.state.data.acf.robs_thoughts}} />
                            }
                            </div>

                        </div>

                        <div className="column is-3 is-hidden-mobile">
                                <AdBox verticle/>
                        </div>

                    </div>

                    <div className="columns">
                        <div className="column is-half">
                            <h5 className="title h5">Share This:</h5>
                            <Sharing title={intro + this.state.data.title.rendered + outro} shareUrl={"https://robsgameshack.com" + this.props.location.pathname} mobile={true} tags={tags} handle={this.state.data.acf.twitter} />
                        </div>
                    </div>
                </div>
                </section>
                <AdBox />
                </>
            )

        }

        return r
    }
}

export default Single
