import React from 'react';
import Header from '../components/Header';
import SEO from '../components/elements/SEO';


const Jobs = () => (
  <>
    <SEO 
      title="Jobs & opportunities to work in video games on Rob's Game Shack" 
      description="Jobs and opportunities inside the games development community, find your next project or team up with others." 
      keywords="Indiegames, gamedev, games, videogames"
    >
      <link rel="canonical" href={"https://robsgameshack.com/jobs"} />
    </SEO>
    <Header title="Jobs for people!" subTitle="Find a Job in your field!" />
    <div className="container">
    <section className="section content">
      <h2>Jobs</h2>
      <p>Lots of Jobs for you and for me and the entire humanrace.</p>
      </section>
    </div>
  </>
);

export default Jobs;
