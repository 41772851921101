import React from 'react';
import Breadcrumbs from '../components/Breadcrumbs';
import Header from '../components/Header';
import ItemCol from '../components/ItemCol';
import SEO from '../components/elements/SEO';

const Design = () => (
  <>
    <SEO 
      title="Video Games Designers, level designers from the Game Dev community on Rob's Game Shack" 
      description="A list of game designers from our great Indie Dev and Game Dev community" 
      keywords="Game Design, Designers, Indiegames, gamedev, games, videogames"
    >
      <link rel="canonical" href={"https://robsgameshack.com/people/designers"} />
    </SEO>
    <Header title="Looking for a Designer?" subTitle="These tallented people will be able to design the hell out of your game idea" />
    <div className="container">
      <Breadcrumbs />
      <ItemCol number="100" title="Level and Game Designers" type="person" filter="11,16" layout="cards"/>
    </div>
  </>
);

export default Design;
