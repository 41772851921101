import React, {useEffect, useState} from 'react'
import { TwitterTimelineEmbed } from 'react-twitter-embed';

export default function Twitter(props) {

    let { width, handle } = props

    const [state, setState] = useState(handle);

    useEffect(() => {

        setState(handle)

    }, [handle])

    return (
        <div className={"column "+width}>
            <TwitterTimelineEmbed 
                sourceType="profile"
                screenName={state}
                options={{height: 400}}
            />
        </div>
    )
}
