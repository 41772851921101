import React from 'react'
import ReloadModal from './elements/Reload'

export default function Footer() {
    return (
        <footer className="footer has-background-dark has-text-light">
            <section className="section">
            <div className="content has-text-centered">
                <p>
                <small>
                    Rob's Game Shack is created and owned by RSC Media Ltd. This site is powered by React, Icons by FontAwesome some art from <a href="https://www.kenney.nl/?utm_source=robsgameshack&utm_medium=footerlink" alt="Kenny Game Assets" target="_blank" rel="noopener nofollow noreferrer">kenney.nl</a>
                </small>
                </p>
                <p>
                    <a href="https://bulma.io" rel="nofollow">
                        <img src="https://bulma.io/images/made-with-bulma--white.png" alt="Made with Bulma" width="128" height="24" />
                    </a>
                </p>
            </div>
            </section>
            <ReloadModal />
        </footer>
    )
}
