import React, { Component } from 'react'
import Loader from '../components/elements/Loader'
import NewsItem from '../components/elements/NewsItem'
import SEO from '../components/elements/SEO'
import Ad from '../components/elements/Ad'

export default class News extends Component {

    constructor(props) {
        super()

        this.state = {
            data: false,
            page: false,
            total: false,
            current: false
        }
    }

    componentDidMount() {

        let url = "https://data.robsgameshack.co.uk/wp-json/wp/v2/posts/?per_page=100&_embed"

        fetch(url)
        .then(res => res.json())
        .then(res => {
            
            let first = res.slice(0,9),
                total = res.length,
                current = 1

            this.setState({
                data: res, 
                page: first, 
                total: total, 
                current: current
            })
            

        })
        .catch(error => {

            console.log("Error", error)

        })



    }

    loadMore() {

        let newState = this.state,
            current = this.state.current,
            per = 10,
            sliceStart = (per * current) - 1,
            sliceEnd = sliceStart + per

        let nextPage = this.state.data.slice(sliceStart, sliceEnd)

        newState.page.push(nextPage)
        newState.current = current++

        this.setState(newState)
        
    }


    render() {

        let r = <Loader />
            
        if(this.state.data) {

            r = this.state.data.map((el, index) => {

                let tags = el._embedded["wp:term"].map((tag, index) => {

                    let r = tag.map((item) => {

                        let r = "#"+item.slug.replace(/-/g, "")

                        return r

                    })

                    r = r.join(" ")

                    return r

                })
    
               tags.push("#RGSPost #IndieDev\n\n")
    
               tags = tags.join(" ");

               let item = <NewsItem key={index} title={el.title.rendered} content={el.excerpt.rendered} date={el.date} slug={el.slug} tags={tags} author={el._embedded.author[0].name}/>

               return item;

            })

        }


        return (
            <section className="section mainbody has-background-primary">
                <SEO 
                    title="Articles, News & Tutorials from Rob & the Indie Community on Rob's Game Shack" 
                    description="All the news, interviews and tutorials from Rob and the indiedev community.  Get involved!" 
                    twitter="robsgameshack"
                >
                    <link rel="canonical" href={"https://robsgameshack.com/news/"} />
                </SEO>
                <div className="container">
                    <h1 className="title h1 has-text-white">News from the Shack!</h1>
                    {r}
                    <Ad />
                    {this.state.page === this.state.total && this.state.total > 1 &&
                        <button className="button" onClick={this.loadMore}>Load More</button>
                    }
                </div>
            </section>
        )
    }
}
