import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { Home, About, Art, Game, Code, Design, Music, People, Studio, Single, Website, AddYou, Jobs, Sponsorship, News, SingleNews, PressKit, Deals, NotFound } from './pages';



const Routes = () => (
  <Switch >
    <Route exact path="/" component={Home} />
    <Route path="/people/composers" component={Music} />
    <Route path="/people/artists" component={Art} />
    <Route path="/people/programmers" component={Code} />
    <Route path="/people/designers" component={Design} />
    <Route path="/people/:slug" component={Single} />
    <Route exact path="/games/:slug/presskit" component={PressKit} />
    <Route exact path="/games/:slug" component={Single} />
    <Route exact path="/team/:slug" component={Single} />
    <Route exact path="/website/:slug" component={Single} />
    <Route exact path="/news/:slug" component={SingleNews} />
    <Route path="/news" component={News} />
    <Route path="/deals" component={Deals} />
    <Route path="/website" component={Website} />
    <Route path="/about" component={About} />
    <Route path="/games" component={Game} />
    <Route path="/people" component={People} />
    <Route path="/team" component={Studio} />
    <Route path="/jobs" component={Jobs} />
    <Route path="/sponsorship" component={Sponsorship} />
    <Route path="/add-yourself" component={AddYou} />
    <Route path="/404" component={NotFound} />
    <Route component={NotFound} />
  </Switch>
);

export default Routes;
