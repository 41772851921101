import React from 'react';
import Header from "../components/Header"
import ItemCol from '../components/ItemCol';
import SEO from '../components/elements/SEO';
import Breadcrumbs from '../components/Breadcrumbs';

const Studio = () => (
  <>
    <SEO 
      title="Independant Games Development Studios on Rob's Game Shack" 
      description="Studios and Teams of people creating games from around the Indie dev community." 
      keywords="Indiegames, gamedev, games, videogames"
    > 
      <link rel="canonical" href={"https://robsgameshack.com/team"} />
    </SEO>
    <Header title="The Studios" subTitle="Collections of gamedev folk on a joint quest to make something awesome" />
    <div className="container">
      <Breadcrumbs />
      <ItemCol number="100" title="The Studios and Teams" type="team" layout="cards"/>
    </div>
  </>
);

export default Studio;
