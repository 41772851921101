import React from 'react'

export default function AuthorBox(props) {
    
    let { author } = props
    
    return (
        <div className="box">
        <article className="media">
            {author.avatar_urls && 
            <figure className="media-left">
                <p className="image is-64x64">
                <img src={author.avatar_urls[96]} alt={author.name} />
                </p>
            </figure>
            }
            <div className="media-content">
                <p className="my-0"><b><small>Author:</small></b></p>
                <div className="content">
                <h5 className="mt-0">{author.name}</h5>
                <p>
                    {author.description}
                </p>
                </div>
                <nav className="level is-mobile">
                <div className="level-left">
                    {author.acf.twitter && 
                    <a href={"https://www.twitter.com/"+author.acf.twitter} target="_blank" rel="noopener nofollow noreferrer" className="level-item">
                        <span className="icon is-small"><i className="fa fa-twitter"></i></span>
                    </a>
                    }
                    {author.acf.instagram && 
                    <a href={"https://www.instagram.com/"+author.acf.instagram} target="_blank" rel="noopener nofollow noreferrer" className="level-item">
                        <span className="icon is-small"><i className="fa fa-instagram"></i></span>
                    </a>
                    }
                    {author.acf.twitch && 
                    <a href={author.acf.twitch} target="_blank" rel="noopener nofollow noreferrer" className="level-item">
                        <span className="icon is-small"><i className="fab fa-twitch"></i></span>
                    </a>
                    }
                    {author.acf.itch && 
                    <a href={author.acf.itch} target="_blank" rel="noopener nofollow noreferrer" className="level-item">
                        <span className="icon is-small"><i className="fab fa-itch-io"></i></span>
                    </a>
                    }
                    {author.acf.youtube && 
                    <a href={author.acf.youtube} target="_blank" rel="noopener nofollow noreferrer" className="level-item">
                        <span className="icon is-small"><i className="fa fa-youtube"></i></span>
                    </a>
                    }
                    {author.acf.sound_cloud && 
                    <a href={author.acf.sound_cloud} target="_blank" rel="noopener nofollow noreferrer" className="level-item">
                        <span className="icon is-small"><i className="fa fa-soundcloud"></i></span>
                    </a>
                    }
                </div>
                </nav>
            </div>
        </article>
        </div>
    )
}
