import React from 'react';
import Breadcrumbs from '../components/Breadcrumbs';
import Header from '../components/Header';
import ItemCol from '../components/ItemCol';
import SEO from '../components/elements/SEO';

const Code = () => (
  <>
    <SEO 
      title="Programmers & Coders from the Game Dev community on Rob's Game Shack" 
      description="A list of game programmers from our great Indie Dev and Game Dev community" 
      keywords="Game Design, Designers, Indiegames, gamedev, games, videogames"
    >
      <link rel="canonical" href={"https://robsgameshack.com/people/programmers"} />
    </SEO>
    <Header title="Find the Coders of our Community!" subTitle="Those smart men and women of tommorows #indiedev project!" />
    <div className="container">
      <Breadcrumbs />
      <ItemCol number="100" title="Coders" type="person" filter="33" layout="cards" />
    </div>
  </>
);

export default Code;
