import React from 'react';
import Breadcrumbs from '../components/Breadcrumbs';
import Header from '../components/Header';
import ItemCol from '../components/ItemCol';
import SEO from '../components/elements/SEO';

const Art = () => (
  <>
    <SEO 
      title="Video Game Artists from the Game Dev community on Rob's Game Shack" 
      description="A list of working artists who are active on the Game dev and Indie dev community of Twitter." 
      keywords="Indiegames, gamedev, games, videogames"
    >
      <link rel="canonical" href={"https://robsgameshack.com/people/artists"} />
    </SEO>
    <Header title="Artistic Tallent" subTitle="Drawn from the depths of the #Gamedev community" />
    <div className="container">
      <Breadcrumbs />
      <ItemCol number="100" title="Artists" type="person" filter="10" layout="cards" />
    </div>
  </>
);

export default Art;
