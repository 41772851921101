import React from 'react'

export default function EmbedItch(props) {
    return (
        <div className="column is-half">
            <iframe title={"Get "+ props.title +" on Itch.io"} src={"https://itch.io/embed/"+props.id} height="167" width="100%" frameborder="0">
            <a href={props.link} rel="nofollow">{props.title}</a>
            </iframe>    
        </div>
    )
}
