import React from 'react';
import Header from '../components/Header';
//import Items from '../components/elements/Items'
import ItemCol from '../components/ItemCol';
import SEO from '../components/elements/SEO';
import Breadcrumbs from '../components/Breadcrumbs';

const Game = () => (
  <>
    <SEO 
      title="Video Game Projects developed by the Independant Game Development Community." 
      description="Independantly developed games created by the great Game Dev and Indie Dev community." 
      keywords="Indiegames, gamedev, games, videogames"
    >
      <link rel="canonical" href={"https://robsgameshack.com/games"} />
    </SEO>
    <Header title="The Games" subTitle="This is what it's all about, that end product, that shining star on the horizon of your destiny!" />
    <div className="container">
      <Breadcrumbs />
      <ItemCol title="Games" type="project" number="100" layout="cards" />
      <div className="columns">
      </div>
    </div>
  </>
);

export default Game;
