import React from 'react'
import { Link } from 'react-router-dom'
import Sharing from '../elements/Sharing'


export default function NewsItem(props) {


    let {title, content, date, slug, author, tags } = props,
        time = date.split("T"),
        shareUrl = "https://robsgameshack.com/news/" + slug


    return (
            <div className="card my-5">
                <div className="card-header">
                    <p className="card-header-title has-text-dark has-text-right has-text-weight-normal">
                        <small>By: {author} on {time[0] + " at " + time[1]}</small>
                    </p>
                </div>
                <div className="card-content">
                        {title &&
                            <h2 className="title"><Link to={"/news/"+slug} dangerouslySetInnerHTML={{__html: title}}/></h2>
                        }
                        {content &&
                            <div dangerouslySetInnerHTML={{__html: content}} />
                        }
                </div>
                <div className="card-footer">

                    <div className="card-footer-item px-0">
                        <div className="columns fullwidth">
                            <div className="column">
                                <Sharing shareUrl={shareUrl} title={title} mobile={true} tags={tags}/>
                            </div>

                            <div className="column is-two-fifths has-text-centered">
                                <Link to={"/news/"+slug} className="button is-info is-fullwidth">Full Article</Link>
                            </div>
                        </div>
                    </div>
                    </div>
            </div>
            )
}
