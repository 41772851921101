import React, { Component } from 'react'
import Loader from '../components/elements/Loader'
import { DiscussionEmbed } from 'disqus-react';
import SEO from '../components/elements/SEO';
import Sharing from '../components/elements/Sharing';
import AuthorBox from '../components/elements/AuthorBox';
import Breadcrumbs from '../components/Breadcrumbs';
import { Redirect } from 'react-router';
import AdBox from '../components/elements/AdBox';
import Ad from '../components/elements/Ad';

export default class SingleNews extends Component {
    constructor(props){
        super(props)

        let slug = props.location.pathname.split("/").pop()

        this.state = {
            data: false,
            type: "posts",
            slug: slug,
            ref: "?utm_source=robsgameshack&utm_medium=singlepage"
        }

        this.controller = new AbortController();
    }

    componentDidMount(){

        let url = "https://data.robsgameshack.co.uk/wp-json/wp/v2/"+this.state.type+"/?_embed&slug="+this.state.slug



        fetch(url, {signal: this.controller.signal})
        .then(res => res.json())
        .then(res => this.setState({data: res[0]}))

    }

    componentDidUpdate(nextProps) {

        if(nextProps.location.pathname !== this.props.location.pathname) {

            let slug = this.props.location.pathname.split("/").pop()

            let newState = this.state;

            newState = {
                data: false,
                type: "posts",
                slug: slug
            }

            let url = "https://data.robsgameshack.co.uk/wp-json/wp/v2/"+newState.type+"/?_embed&slug="+newState.slug

            fetch(url)
            .then(res => res.json())
            .then(res => {
                newState.data = res[0]
                this.setState(newState)
            })

        }

    }

    componentWillUnmount()
    {
        this.controller.abort();
    }

    cleanString(string) {

        return string.replace("&#8217;", "'");

    }

    render() {

        let r = <Loader />

        if(this.state.data === undefined)
            r = <Redirect to="/404" />

        if(this.state.data) {

            let cleanTitle = this.cleanString((this.state.data.acf.seo_title) ? this.state.data.title.rendered : this.state.data.title.rendered),
                //description = this.state.data.acf.seo_description,
                auth = "robsgameshack",
                name = this.state.data['_embedded'].author[0].name,
                twitter = (this.state.data['_embedded'].author[0].twitter) ? this.state.data['_embedded'].author[0].twitter : "",
                intro = "Take a peek at this post: \n\n", 
                outro = "\n\nIf you like it leave a comment! :-)\n\n",
                seo_description = this.state.data.acf.seo_description,
                featured_image = this.state.data.acf.featured_image,
                seo_image = (this.state.data.acf.featured_image) ? this.state.data.acf.featured_image.sizes.large : "https://robsgameshack.com/logo512.png"
        
            let tags = this.state.data._embedded['wp:term'][0].map(el => {

                let r = "#"+el.slug.replace(/-/g, "")

                return r

            })

            tags.push("#RGSPost #IndieDev\n\n")

            tags = tags.join(" ");

            let article = `
            {
                "@context": "https://schema.org",
                "@type": "NewsArticle",
                "mainEntityOfPage": {
                    "@type": "WebPage",
                    "@id": "https://robsgameshack.com/news/${this.state.slug}"
                },
                "url": "https://robsgameshack.com/news/${this.state.slug}",
                "headline": "${cleanTitle}",
                "image": [
                    "${seo_image}"
                ],
                "datePublished": "${this.state.data.date}",
                "dateModified": "${this.state.data.modified}",
                "author": {
                    "@type": "Person",
                    "name": "${name}"
                },
                "editor": "Rob MacKay",
                "publisher": {
                    "@type": "Organization",
                    "name": "Rob's Game Shack",
                    "logo": {
                    "@type": "ImageObject",
                    "url": "https://robsgameshack.com/logo512.png"
                    }
                },
                "description": "${seo_description}"
            }
            `
            let url = "https://robsgameshack.com"+this.props.location.pathname

            r = (
                <>
                <SEO 
                    title={cleanTitle + " - Written by " + this.state.data['_embedded'].author[0].name} 
                    description={seo_description} 
                    twitter={auth}
                    image={(featured_image) ? featured_image.sizes.large : ""}
                >
                    <script type="application/ld+json">
                        {article}
                    </script>
                    <link rel="canonical" href={url} />
                </SEO>
                <section className="hero is-primary is-bold">
                    <div className="hero-body">
                        <div className="container">
                            <h1 className="title is-1"  dangerouslySetInnerHTML={{__html: this.state.data.title.rendered}} />
                            <h2 className="subtitle">
                                By {this.state.data['_embedded'].author[0].name} <small className="is-size-7">on {this.state.data.date.split("T")[0]} at {this.state.data.date.split("T")[1]}</small>
                            </h2>
                        </div>   
                    </div>
                </section> 
                <Ad />
                <section className="section mainbody article pt-0">
                    <Breadcrumbs className="pl-0" title={this.state.data.title}/>
                    <div className="container">
                        <div className="columns">
                            <div className="column is-9">
                                <div className="content">
                                    <div dangerouslySetInnerHTML={{__html: this.state.data.content.rendered}} />
                                </div>
                                <div className="level">
                                    <div className="level-left">
                                    <Sharing title={intro + this.state.data.title.rendered + outro} shareUrl={"https://robsgameshack.com" + this.props.location.pathname} mobile={true} tags={tags} handle={twitter} />
                                    </div>
                                </div>
                            </div>
                            <div className="column is-3 is-hidden-mobile">
                                <AdBox verticle/>
                            </div>
                        </div>
                        <AuthorBox author={this.state.data['_embedded'].author[0]} />
                    </div>
                </section>
                <Ad />
                <section className="section comments">
                    <div className="container">
                        <DiscussionEmbed
                            shortname='robsgameshack'
                            config={
                                {
                                    url: "https://robsgameshack.com/news/"+this.state.slug,
                                    identifier: this.state.data.id.toString(),
                                    title: this.state.data.title.rendered,
                                }
                            }
                        />
                    </div>
                </section>
                
                </>
            )

        }

        return r
    }
}
